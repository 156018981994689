<div class="isc-form-modal" *ngIf="data.iscFormInputs">
    <div class="modal-header">
        <span>{{ data.modalTitle | translate }}</span>
        <mat-icon id="form-modal-x-button" title="{{ 'Close' | translate }}" [mat-dialog-close]="">close</mat-icon>
    </div>

    <div>
        <fr-isc-form 
            [Id]="data.iscFormInputs.Id"
            [FDN]="data.iscFormInputs.FDN"
            [formModel]="data.iscFormInputs.formModel"
            [formConfig]="data.iscFormInputs.formConfig"
            [mode]="data.iscFormInputs.mode"
            [buttons]="data.iscFormInputs.buttons">
        </fr-isc-form>
    </div>
</div>