<app-section [header]="'Interface Configuration / ' + deployment?.deploymentname + ''">
  <div class="bg-info">
    <h3>How to setup policy access</h3>
    <ul>
      <li>
        - Log into your AWS account
      </li>
      <li>
        - Go to S3 and click on the bucket that you indicated as your source bucket
      </li>
      <li>
        - Click on the "Permissions" tab
      </li>
      <li>
        - Scroll down to the "Bucket policy" section
      </li>
      <li>
        - Inside the "Statement" list, you will see one or more statement(s), indicated by a pair of curly brackets and
        separated by commas
      </li>
      <li>
        - If there is only one statement inside the "Statement" list and its "Principal" field is just
        <span>&#123;&#125;</span>,
        then highlight that statement and delete it. Otherwise, add a comma to the last statement in your list
      </li>
      <li>
        - Add the following to your "Statement" list (click to copy s3-policy.json to clipboard:
        <app-copy-button buttonName="copy-s3-policy-example" [copyText]="getJsonExample() | json"></app-copy-button>)
      </li>
      <li>
        - Scroll down to the bottom of the page and click on "Save changes"
      </li>
      <li *ngIf="formModel.targetType?.value === 's3' && formModel.CustomerDestS3Bucket!=formModel.CustomerSrcS3Bucket">
        - Do the same for the destination bucket, but add this policy (click to copy s3-policy-target.json to clipboard:
        <app-copy-button buttonName="copy-s3-policy-example-dest" [copyText]="getJsonExampleDest() | json">
        </app-copy-button>)
      </li>
    </ul>
  </div>

  <div class="form-body-desktop">
    <fr-isc-form Id="hmtsConfigurationForm" [FDN]="configurationsFDN" [formModel]="formModel" [buttons]="buttons">
    </fr-isc-form>
  </div>
</app-section>
