import { Component, OnDestroy } from '@angular/core';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AdministrationService } from '../administration.service';
import { User, UserDeleteError, UsersService } from 'api';
import { ConfirmationDialogComponent, ConfirmationDialogConfig } from '@intersystems/confirmation-dialog';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss'],
})
export class UsersPageComponent implements OnDestroy {
  constructor(
    private administrationService: AdministrationService,
    private sharedService: SharedService,
    private usersService: UsersService,
    private dialog: MatDialog
  ) {}

  private refreshToken = new BehaviorSubject<void>(undefined);
  private unsubscribeAll = new Subject<void>();

  users$ = this.refreshToken.pipe(
    switchMap(() => this.administrationService.users$()),
    map(users => {
      users.forEach(u => {
        if (u.subscriptions) {
          Object.values(u.subscriptions).forEach(v => {
            if (v.provider === 'aws') u.subscriptionid = v.subscriptionid;
          });
        }
      });

      return users;
    }),
    takeUntil(this.unsubscribeAll)
  );

  deleteUser(event: User) {
    const dialogConfig: ConfirmationDialogConfig = {
      title: 'Delete User',
      primary: `Are you sure you want to delete '${event.username}'?`,
      hideCloseButton: true,
      buttons: {
        primary: {
          text: 'Confirm',
        },
        secondary: {
          text: 'Cancel',
        },
      },
    };
    this.dialog
      .open(ConfirmationDialogComponent, {
        panelClass: 'fr-layout-wrapper-mat-dialog-panel',
        data: dialogConfig,
      })
      .afterClosed()
      .pipe(
        filter(response => response?.button == 'primary'),
        switchMap(() => this.usersService.deleteUser(event.username)),
        tap(() => this.sharedService.showSuccess(`'User '${event.username}' deleted`)),
        tap(() => this.refreshToken.next()),
        catchError((response: HttpErrorResponse) => of(this.handleDeleteError(response, event))),
        takeUntil(this.unsubscribeAll)
      )
      .subscribe();
  }

  private handleDeleteError(response: HttpErrorResponse, user: User) {
    if (response.status == 409) {
      let error = response.error as UserDeleteError;
      let message = `User '${user.username}' could not be deleted:\n\n`;

      for (let d of error.active_deployments) {
        message += `- Active Deployment: '${d.deployment_name}'\n`;
      }

      if (error.active_subscriptions.length > 0) {
        message += `- User has active subscription`
      }

      this.sharedService.showAlert(message, true);
    } else {
      this.sharedService.showAlert(`Error deleting '${user.username}'`);
    }
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
