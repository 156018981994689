import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginatorConfig, TableConfig } from '@intersystems/table';
import { TableEnhancedIdService } from '../../../core/table-enhanced-id.service';
import { SharedUtils } from '../../../shared/shared-utils';
import { DeploymentOptions } from '../../../deployments/data/deployment-options';

@Component({
  selector: 'app-services-table',
  templateUrl: './services-table.component.html',
  styleUrls: ['./services-table.component.scss'],
})
export class ServicesTableComponent {
  @Input() data!: any;

  @Output() addService: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteService: EventEmitter<any> = new EventEmitter<any>();

  tableConfig: TableConfig = {
    key: 'services-table',
    cssNoDataMessageClass: 'no-data-message',
    noDataMessage: 'Add service to see calculating price',
    stickyHeaderRow: true,
    cssTRClassFromRow: TableEnhancedIdService.setTableRowIdColumn('type'),
    header: {
      title: 'Service',
    },
    columns: [
      SharedUtils.getTableColumn(
        'type',
        'Offer Type',
        row =>
          (DeploymentOptions.serviceDesc[row.type] || row.type) + (row.enableIntegratedML ? ' with IntegratedML' : ''),
      ),
      SharedUtils.getTableColumn('perHour', 'Hourly'),
      SharedUtils.getTableColumn('perMonth', 'Monthly'),
      SharedUtils.getTableColumn('perYear', 'Annual'),
      {
        key: 'action',
        id: 'action',
        title: 'Action',
        cellDisplay: {
          preset: 'actionsIcons',
          actionsIcons: {
            delete: {
              id: 'delete',
              callback: (_event, row) => this.deleteService.emit(row),
              hidden: row => row.type === 'Total',
            },
          },
        },
      },
    ],
  };

  paginatorConfig: PaginatorConfig = {
    length: this.data?.value.length,
    pageSize: 10,
    pageSizeOptions: [5, 10, 20, 50],
  };
}
