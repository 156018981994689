export function transformRegionToReadableString(value: string): string {
  switch (value) {
    case 'af-south-1':
      return 'Africa (Cape Town)';
    case 'us-east-1':
      return 'US East (N. Virginia)';
    case 'us-east-2':
      return 'US East (Ohio)';
    case 'us-west-2':
      return 'US West (Oregon)';
    case 'eu-west-1':
      return 'Europe (Ireland)';
    case 'eu-west-2':
      return 'Europe (London)';
    case 'eu-west-3':
      return 'Europe (Paris)';
    case 'eu-central-1':
      return 'Europe (Frankfurt)';
    case 'ca-central-1':
      return 'Canada (Central)';
    case 'il-central-1':
      return 'Israel (Tel Aviv)';
    case 'ap-southeast-2':
      return 'Asia Pacific (Sydney)';
    case 'eastus2':
      return 'East US 2 (Virginia)';
    default:
      return value;
  }
}
