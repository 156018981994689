export interface ServiceGroup {
  title: string;
  subtitle: string;
  img: string;
  offers?: OffersEntity[];
}
export interface OffersEntity {
  name: string;
  caption: string;
  enabled: boolean;
  hidden?: boolean;
  architecture?: string;
  regions?: string;
  sizes?: string;
  ns?: string;
  subOffers?: SubOffersEntity[];
}
export interface SubOffersEntity {
  name: string;
  caption: string;
  optionLabel: string;
  property: string;
}

export const SERVICES_DATA: ServiceGroup[] = [
  {
    title: 'InterSystems IRIS Cloud Services',
    subtitle: 'The Next Generation Data Platform.',
    img: 'assets/images/IRIS.svg',
    offers: [
      {
        name: 'ics',
        caption: 'InterSystems IRIS Cloud Managed Service',
        enabled: false,
        subOffers: [
          {
            name: 'iris_health',
            caption: 'IRIS for Health',
            optionLabel: 'Enable InterSystems IRIS for Health',
            property: 'iris_health',
          },
        ],
      },
      {
        name: 'sqlaas',
        caption: 'InterSystems IRIS Cloud SQL',
        architecture: 'icca',
        regions: 'icca',
        sizes: 'icca',
        ns: 'USER',
        enabled: false,
        subOffers: [
          {
            name: 'iml',
            caption: 'IntegratedML',
            optionLabel: 'Enable Cloud IntegratedML',
            property: 'integratedMLEnabled',
          },
        ],
      },
      { name: 'spc', caption: 'Supply Chain Cloud', enabled: false },
      {
        name: 'doc',
        caption: 'Cloud Document Service',
        architecture: 'icca',
        regions: 'icca',
        sizes: 'icca',
        ns: 'USER',
        enabled: false,
      },
      { name: 'sds', caption: 'Smart Data Services', architecture: 'sds', regions: 'sds', enabled: false },
      // Excluding business 360 as option while Azure support in development
      { name: 'business360', caption: 'InterSystems Data Fabric Studio', enabled: false },
    ],
  },
  {
    title: 'InterSystems FHIR Cloud Services',
    subtitle: 'Modern Healthcare Data Exchange.',
    img: 'assets/images/IRIS.svg',
    offers: [
      { name: 'fhiraas', caption: 'InterSystems FHIR Server', enabled: false, hidden: false },
      {
        name: 'fhirserver',
        caption: 'Cloud FHIR Server',
        architecture: 'icca',
        regions: 'icca',
        sizes: 'icca',
        ns: 'FHIRDB',
        enabled: false,
      },
      { name: 'hmts', caption: 'InterSystems FHIR Transformation Services', enabled: false },
    ],
  },
  {
    title: 'HealthShare Services',
    subtitle: 'Advanced Healthcare Interoperability. Unprecedented speed.',
    img: 'assets/images/HS.svg',
    offers: [
      { name: 'hcc', caption: 'Health Connect Cloud', enabled: false },
      { name: 'hspdc', caption: 'HealthShare Provider Directory', enabled: false },
    ],
  },
  {
    title: 'Networking Services',
    subtitle: 'Secure Connectivity.',
    img: 'assets/images/networkconnect.png',
    offers: [
      { name: 'lol', caption: 'Network Connect', enabled: false },
      { name: 'tsp', caption: 'File Transfer', enabled: false, hidden: true },
    ],
  },
];
