import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountPageComponent } from './account/account-page.component';

export const routes: Routes = [
  {
    path: 'account',
    component: AccountPageComponent,
    data: {
      caption: 'Account',
    },
  },
  {
    path: '',
    redirectTo: 'account',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
