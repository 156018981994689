import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TableConfig } from '@intersystems/table';
import { Deployment } from 'src/app/core/interfaces/deployment';
import { TableEnhancedIdService } from 'src/app/core/table-enhanced-id.service';
import { SharedUtils } from 'src/app/shared/shared-utils';

@Component({
  selector: 'app-deployment-view',
  templateUrl: './deployment-view.component.html',
  styleUrls: ['./deployment-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeploymentViewComponent implements OnChanges {
  @Input() deployment: Deployment = null;
  @Output() close = new EventEmitter<void>();
  @Output() copyClick = new EventEmitter<string>();

  deploymentData: any[] = [];
  resourcesListData: any[] = [];

  InstanceTableConfig: TableConfig = {
    key: 'instances-table',
    header: { title: 'Instance Status' },
    cssNoDataMessageClass: 'no-data-message',
    noDataMessage: 'No Instance Info Available.',
    cssTRClassFromRow: TableEnhancedIdService.setTableRowIdColumn('resourcename'),
    columns: [
      SharedUtils.getTableColumn('resourcename', 'Instance Name'),
      SharedUtils.getTableColumn('resourcestatus', ' Instance State'),
      SharedUtils.getTableColumn('cloudid', 'InstanceId'),
      SharedUtils.getTableColumn('private_ip', 'Ip Address'),
      //SharedUtils.getTableColumn('kit', 'Kit'),
    ],
  };
  tableConfig: TableConfig = {
    key: 'deployment-table',
    noDataMessage: 'No data',
    useSearch: true,
    searchConfig: {
      placeholderLabel: 'Search',
    },
    cssTRClassFromRow: TableEnhancedIdService.setTableRowIdColumn('key'),
    columns: [
      SharedUtils.getTableColumn('key'),
      SharedUtils.getTableColumn('value'),
      {
        id: 'actions',
        key: 'actions',
        title: 'Actions',
        cellDisplay: {
          preset: 'actionsIcons',
          actionsIcons: {
            iconsOrder: ['copy'],
            copy: {
              id: 'copyId',
              tooltip: { text: 'Copy Deployment ID' },
              customSvgIcon: 'content_copy',
              callback: (_event, item) => this.copyClick.emit(item.value),
            },
          },
        },
      },
    ],
  };

  ngOnChanges(changes) {
    if (changes.deployment) {
      this.deploymentData = this.getKeyValueArray(this.deployment);
      if (this.deployment.resource_list) this.resourcesListData = this.getKeyValueArray(this.deployment.resource_list);
    }
  }

  getKeyValueArray(obj) {
    const result = [];
    Object.keys(obj).forEach(key => result.push({ key, value: obj[key] }));
    return result;
  }

  onIndexChange(index) {
    if (index == 2) this.close.emit();
  }
}
