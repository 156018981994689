import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { MatDialog,MatDialogRef } from '@angular/material/dialog';

import { SharedService } from 'src/app/shared/services/shared.service';

import { IrisConnection } from '../../icca-common/model/connection'; 
import { DeploymentObject } from 'api';


@Injectable({ providedIn: 'root' })
export class IMLService {

 
  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private sharedService: SharedService,
  ) {}
  
  imlFormData = {
    create: {
      opened: false,
      modelName: '',
      selectedTable: '',
      selectedCol: '',
    },
    train: {
      opened: false,
      selectedModel: '',
      trainedName: '',
    },
    validate: {
      opened: false,
      modelToValidate: '',
      trainedModelToValidate: '',
      selectedValidateModel: '',
      selectedTableForValidation: '',
      validationName: '',
    },
    predict: {
      opened: false,
      modelToPredict: '',
      predictModelSelected: '',
      predictTrainedModelSelected: '',
      predictSelectedTable: '',
      predictClassification: '',
      selectedPredict: '',
      predictField: '',
      predictFieldType: '',
      predictTop: '100',
      sqlPredict:'', 
    },
  }
  
  getDisplay(value,convertNumericToFloat:boolean=true) {
    
    var ret = value.substring(0,value.indexOf(' '));
    if (convertNumericToFloat) {
      var table=value.split(" ");
      if (table[table.length-1] == 'NUMERIC') {
        ret=`${value.substring(0,value.indexOf(' '))} FLOAT`;
      }
    }
    
    return ret;
  }

 
}


