import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PortalApiComponent } from './portal-api/portal-api.component';
import { PortalAdminApiComponent } from './portal-admin-api/portal-admin-api.component';

export const routes: Routes = [
  {
    path: 'portalapi',
    component: PortalApiComponent,
    data: {
      caption: 'Portal API',
    },
  },
  {
    path: 'portaladminapi',
    component: PortalAdminApiComponent,
    data: {
      caption: 'Portal Admin API',
      adminOnly: true,
    },
  },
  {
    path: '',
    redirectTo: 'portalapi',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ResourceCenterRoutingModule {}
