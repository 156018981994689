/**
 * Portal Admin
 * Thehose APIs are only available for portal administrators 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: portal-dev@intersystems.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Performance Profile
 */
export interface PerformanceProfile { 
    item_type?: string;
    perfprofileid?: string;
    size?: string;
    offer?: string;
    cloud?: string;
    hugepages?: string;
    routines?: string;
    gmheap?: string;
    locksiz?: string;
    globals8kb?: string;
    created_at?: string;
    frame?: string;
    instance_type?: string;
}

