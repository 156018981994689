<fr-table
  [config]="tableConfig"
  [(data)]="queryHistory"
  [safeMode]="true"
  [paginatorConfig]="paginatorConfig"
  Id="query-history-table"
>

  <div
    class="custom-table-header-space"
    table-header-space
    title="Hide Statement History">

    <button mat-flat-button class="fr-primary button-padding" color="primary" 
      (click)="hideDrawer()" >
      Hide
    </button>
  </div>
</fr-table>
