<mat-card>

  <mat-label>Select table to import <b>{{filename}}</b> into
    <app-info-button [htmlText]="infoObject.table.htmlText" [title]="infoObject.table.infoTitle"></app-info-button>
  </mat-label>

  <mat-form-field id="table-select-field">
    <mat-select id="table-select">
      <mat-option *ngFor="let table of tableData" [value]="table.toString().replace(',','.')"
        (onSelectionChange)="tableSelected($event)" [id]="table.toString().replace(',','.')">
        {{table.toString().replace(',','.')}}
      </mat-option>
    </mat-select>
  </mat-form-field>



  <mat-checkbox id="has-headers-check" class="mat-check" [(ngModel)]="hasHeaders" [disabled]="(selectedTable=='')">
    Import file has header row
    <app-info-button [htmlText]="infoObject.headers.htmlText" [title]="infoObject.headers.infoTitle"></app-info-button>
  </mat-checkbox>

  <mat-checkbox id="headers-match-check" class="mat-check" [(ngModel)]="headersMatch" [disabled]="(!hasHeaders)">
    Field names in header row match column names in selected table
    <app-info-button [htmlText]="infoObject.headersMatch.htmlText" [title]="infoObject.headersMatch.infoTitle">
    </app-info-button>
  </mat-checkbox>

  <div>
    <mat-label>Column order in file
      <app-info-button [htmlText]="infoObject.columns.htmlText" [title]="infoObject.columns.infoTitle">
      </app-info-button>
    </mat-label>

    <mat-accordion id="mat-acc-col-list">
      <mat-expansion-panel (opened)="showColumns = true" (closed)="showColumns = false"
        [disabled]="(this.selectedTable=='')||((headersMatch)&&(hasHeaders))" id="mat-exp-col-list">
        <mat-expansion-panel-header id="mat-exp-header-col-list">
          <mat-panel-title id="mat-exp-title-col-list">
            {{getColumnLabel()}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!--contents of expanded panel-->
        <mat-form-field id="col-order-field">
          <mat-chip-list id="col-order-list" [disabled]="((headersMatch)&&(hasHeaders))" class="mat-check" cdkDropList
            cdkDropListOrientation="vertical" (cdkDropListDropped)="drop($event)">
            <mat-chip class="mat-check" [removable]="true" (removed)="remove(col)" cdkDrag [id]="col.name"
              *ngFor="let col of colList">
              {{col.display}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
          <button mat-stroked-button class="fr-secondary" color="primary" (click)="loadColumns()"
            [disabled]="(loadInProgress)||(selectedTable=='')||((headersMatch)&&(hasHeaders))">
            <mat-icon>refresh</mat-icon>
            Reset Columns
          </button>
        </mat-form-field>
      </mat-expansion-panel>
    </mat-accordion>
  </div>


  <!--
  <div>
    <mat-label>Column order in file (drag and drop to reorder)
      <app-info-button [htmlText]="infoObject.columns.htmlText" [title]="infoObject.columns.infoTitle">
      </app-info-button>
    </mat-label>

    <mat-form-field id="col-order-field">
      <mat-chip-list id="col-order-list" [disabled]="((headersMatch)&&(hasHeaders))" class="mat-check" cdkDropList
        cdkDropListOrientation="vertical" (cdkDropListDropped)="drop($event)">
        <mat-chip class="mat-check" [removable]="true" (removed)="remove(col)" cdkDrag [id]="col.name"
          *ngFor="let col of colList">
          {{col.display}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
      <button mat-flat-button class="fr-primary" color="primary" (click)="loadColumns()"
        [disabled]="(loadInProgress)||(selectedTable=='')||((headersMatch)&&(hasHeaders))">
        <mat-icon>refresh</mat-icon>
        Reset Columns
      </button>
    </mat-form-field>
  </div>
  -->
  <div id="field-container">
    <div class="card">
      <div>Rows to skip after header row</div>
      <mat-form-field id="skip-rows-field">
        <input id="skip-rows-input" [(ngModel)]="skipRows" matInput type="number" [value]="skipRows">
      </mat-form-field>
    </div>

    <div class="card">
      <div>Line separator character</div>
      <mat-form-field id="line-separator-field">
        <mat-select id="line-separator" [(value)]="lineSeparator">
          <mat-option *ngFor="let lineSeparator of lineSeparatorData" [value]="lineSeparator">
            {{lineSeparator}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="card">
      <div>Column separator character</div>
      <mat-form-field id="col-separator-field">
        <input
          [formControl]="colSeparatorControl"
          id="col-separator-input"
          matInput
          type="text"
        />
        <mat-error class="error-note" *ngIf="colSeparatorControl.hasError('invalidColSeparator')">
          Input must be a single ASCII character
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <mat-card-actions>
    <button id="btn-import-csv-back" mat-stroked-button matStepperPrevious class="fr-tertiary button-padding"
      color="primary">
      <mat-icon>navigate_before</mat-icon>
      Back
    </button>

    <button id="btn-import-csv" mat-flat-button class="fr-primary" color="primary" (click)="importFile()"
      [disabled]="(selectedTable=='')">Import File
      <mat-icon>navigate_next</mat-icon>
    </button>
  </mat-card-actions>
  <div>
    <mat-spinner id="spinner-import-csv" *ngIf="loadInProgress" [diameter]="30"></mat-spinner>
  </div>


</mat-card>