<div class="isc-form-modal">
  <div class="modal-header">
    <span>{{ data.title | translate }}</span>
    <mat-icon id="form-modal-x-button" title="{{ 'Close' | translate }}" [mat-dialog-close]="">close</mat-icon>
  </div>

  <div>
    <fr-table [config]="tableLogs" [(data)]="logData" [safeMode]="true" [paginatorConfig]="paginatorConfig"
      Id="deployment-logs-table">
    </fr-table>
  </div>
</div>