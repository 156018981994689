import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeploymentObject, Deployments, TenantsService } from 'api';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { delay, filter, map, repeatWhen, switchMap, takeWhile, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../core/auth.service';
import { MainMenuService } from '../core/main-menu.service';
import { SharedService } from '../shared/services/shared.service';
import { SkipperLogResponse } from './sds/model/skipper-log.interface';
import { SERVICES_DATA } from './common/create/servicesData';

const nonRefreshStatuses = ['COMPLETE', 'DELETED', 'FAILED_DELETE', 'FAILED', ''];

const nonRefreshServiceStates = [false, 'RUNNING', 'STOPPED', 'FAILED TO START', 'FAILED TO STOP'];

@Injectable({
  providedIn: 'root',
})
export class DeploymentsService {
  constructor(
    private tenantsService: TenantsService,
    private authService: AuthService,
    private sharedService: SharedService,
    private http: HttpClient,
    private mainMenuService: MainMenuService,
  ) {
    combineLatest([this.authService.user$, this.sharedService.refresh$, this._reloadDeployments$])
      .pipe(
        tap(([user]) => (!user ? this._deployments.next(null) : '')),
        switchMap(() =>
          this.loadDeployments$().pipe(
            repeatWhen(obs => obs.pipe(delay(10000))),
            takeWhile(
              data =>
                data.deployments.length > 0 &&
                data.deployments.filter(
                  deployment =>
                    (!nonRefreshStatuses.includes(deployment.status) ||
                      (deployment.status == 'COMPLETE' &&
                        !nonRefreshServiceStates.includes((deployment as any).service_status))) &&
                    this.getOfferArchitecture(deployment.deploymenttype) != 'icca', //todo
                ).length > 0,
            ),
          ),
        ),
      )
      .subscribe();
  }

  private _deployments = new BehaviorSubject<Deployments>(null);
  private _reloadDeployments$ = new BehaviorSubject<void>(null);

  get deployments$(): Observable<any> {
    return this._deployments.asObservable();
  }

  get deployments(): any {
    return this._deployments.value?.deployments;
  }

  loadDeployments$(): Observable<Deployments> {
    const user = this.authService.user;
    if (!user) return of({ deployments: [] });

    return this.tenantsService.getDeploymentsByTenantId(this.authService.getTenantId()).pipe(
      map(deployments => {
        return deployments?.deployments
          ? { deployments: deployments.deployments.sort((a, b) => a.deploymentname.localeCompare(b.deploymentname)) }
          : undefined;
      }),
      filter(deployments => !!deployments),
      tap(deployments => this._deployments.next(deployments)),
      tap(() => this.mainMenuService.updateMenu()),
    );
  }

  reloadDeployments() {
    this._reloadDeployments$.next();
  }

  deployment$(deploymentId: string): Observable<DeploymentObject> {
    return this.deployments$.pipe(map(data => this.findDeployment(data?.deployments, deploymentId)));
  }

  findDeployment(deployments: DeploymentObject[], deploymentId: string): DeploymentObject {
    if (!deployments) return null;
    let foundDeployment = null;
    deployments.forEach(deployment => {
      if (deployment.deploymentid == deploymentId) {
        foundDeployment = deployment;
      }
    });
    return foundDeployment;
  }

  isActive(deployment) {
    if (!deployment) return false;
    if (deployment.deploymenttype == 'fhiraas')
      return deployment.status == 'COMPLETE' && deployment.service_status == 'RUNNING';
    return deployment.status == 'COMPLETE';
  }

  createIccaDeployment(type: string, body: any) {
    const url = `${this.getAPIURL(type, body.region)}/cluster`;
    return this.http.post(url, body);
  }

  scaleIccaDeployment(type: string, deploymentId: string, region: string, body: any) {
    const url = `${this.getAPIURL(type, region)}/cluster/scale/${deploymentId}`;
    return this.http.patch(url, body);
  }

  patchIccaDeployment(type: string, region: string, body: any) {
    const url = `${this.getAPIURL(type, region)}/cluster/${body.deploymentId}`;
    return this.http.patch(url, body);
  }

  patchIccaDeploymentType(deploymentId: string, region: string, body: any) {
    const url = `${this.getAPIURL(body.deploymentType, region)}/cluster/deploymentType/${deploymentId}`;
    return this.http.patch(url, body);
  }
  upgradeIccaDeployment(type: string, region: string, body: any) {
    const url = `${this.getAPIURL(type, region)}/cluster/upgrade/${body.deploymentId}`;
    return this.http.patch(url, body);
  }
  getSkipperLogs(type: string, region: string, deploymentId: string, logCount = 10): Observable<SkipperLogResponse> {
    const url = `${this.getAPIURL(type, region)}/cluster/logs/${region}/${deploymentId}/${logCount}`;
    return this.http.get(url) as Observable<SkipperLogResponse>;
  }

  retryDeployment(type: string, deploymentId: string, region: string) {
    const url = `${this.getAPIURL(type, region)}/cluster/retry`;
    return this.http.post(url, { deploymentId: deploymentId });
  }

  getSDSVersions(type: string) {
    const url = `${this.getAPIURL(type)}/cluster/versions`;
    return this.http.get(url) as Observable<any>;
  }

  formatAllowedCIDRs(allowedCIDRs: string[]): string[] {
    //take any single IPs and add /32 to put into CIDR format
    //if no IPs, pass 0.0.0.0/0 to allow all
    if (allowedCIDRs.length == 0) {
      allowedCIDRs.push('0.0.0.0/0'); //enable all IPs
    }

    allowedCIDRs.forEach(function (ip, index) {
      ip = ip.trim();
      //check if cidr exists
      let cidr;
      if (ip.includes('/')) {
        cidr = ip.substring(ip.lastIndexOf('/') + 1);
      } else {
        cidr = '';
      }
      if (cidr == '') {
        this[index] = `${ip}/32`;
      }
    }, allowedCIDRs);

    return allowedCIDRs;
  }

  formatAllowedCIDRsToDisplay(allowedCIDRs: string[]): string[] {
    if (!allowedCIDRs) {
      return [];
    }
    let allAllowed = true;
    allowedCIDRs.forEach(ip => {
      if (ip != '0.0.0.0/0') {
        allAllowed = false;
      }
    });

    if (allAllowed) {
      allowedCIDRs = ['all IP addresses are allowed'];
    }
    return allowedCIDRs;
  }

  getDeploymentName(deploymentId: string): string {
    return this.findDeployment(this._deployments.getValue()?.deployments, deploymentId)?.deploymentname;
  }

  deleteSkipperDeployment$(type: string, deploymentId: string, region: string): Observable<any> {
    const url = `${this.getAPIURL(type, region)}/cluster`;
    const body = { deploymentID: deploymentId };
    return this.http.request('delete', url, { body: body });
  }

  deleteIccaDeployment$(deploymentType: string, deploymentId: string, region: string): Observable<any> {
    const url = `${this.getAPIURL(deploymentType, region)}/cluster/${deploymentId}`;
    return this.http.delete(url);
  }
  skipperDeploymentInfo$(region: string, type: string, deploymentId: string): Observable<any> {
    const url = `${this.getAPIURL(type, region)}/cluster/info/${region}/${deploymentId}`;
    return this.http.get<any>(url);
  }
  getOfferDefinition(offer) {
    let offerDefinition;
    SERVICES_DATA.some(function (section) {
      section.offers.some(function (o) {
        if (o.name === offer) {
          offerDefinition = o;
          return true;
        }
      });
      if (offerDefinition) return true;
    });
    return offerDefinition;
  }

  getOfferArchitecture(offer) {
    return this.getOfferDefinition(offer)?.architecture;
  }
  getOfferNamespace(offer) {
    return this.getOfferDefinition(offer)?.ns;
  }
  getOfferRegions(offer) {
    return this.getOfferDefinition(offer)?.regions;
  }
  getAPIURL(deploymentType, region?) {
    if (typeof region == 'undefined') {
      //default to us-east-1 if no region is specified
      region = environment['ICCA_REGIONS'] ? environment['ICCA_REGIONS'][0] : 'us-east-1';
    }
    if (deploymentType == 'sds') {
      return environment['OFFER_API'][deploymentType];
    }

    return `https://${region}.${environment['ICCA_STAGE']}.${environment['ICCA_ACCOUNT']}.isccloud.io`;
    //return `https://${environment['ICCA_STAGE']}.${this.getAWSRegionAcronym(region)}.ic.${environment['ICCA_ACCOUNT']}.isccloud.io`;
  }

  getAWSRegionAcronym(region) {
    switch (region) {
      case 'us-east-1':
        return 'use1';
      case 'us-east-2':
        return 'use2';
      case 'us-west-1':
        return 'usw1';
      case 'us-west-2':
        return 'usw2';
      case 'eu-central-1':
        return 'euc1';
      default:
        console.error('Unknown region: ' + region);
        return '';
    }
  }

  uploadIndividualFileToS3(signatureAndPolicy, fileContent): Observable<any> {
    const bodyFormData = new FormData();
    for (const key in signatureAndPolicy.fields) {
      bodyFormData.append(key, signatureAndPolicy.fields[key]);
    }
    const blob = new Blob([fileContent], { type: 'octet/stream' });
    bodyFormData.append('file', blob);
    return this.http.post(signatureAndPolicy.url, bodyFormData);
  }
}
