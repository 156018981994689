import { Component, Inject, OnInit } from '@angular/core';
import { IscFormModalData } from '@intersystems/isc-form';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormButton, FDN, VALIDATORS } from '@intersystems/isc-form';
@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss'],
})
export class GenericDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<GenericDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IscFormModalData,
  ) {}
}
