import { Component, OnDestroy, EventEmitter, Input, Output, OnInit, OnChanges } from '@angular/core';
import { FDN, Field } from '@intersystems/isc-form';
import { FieldWrapper } from '@ngx-formly/core';
import { Subject } from 'rxjs';

import { MatSliderChange } from '@angular/material/slider';
import { DeploymentOptions } from 'src/app/deployments/data/deployment-options';


@Component({
  selector: 'app-isc-form-field-scaling-wrapper',
  templateUrl: './isc-form-field-scaling-wrapper.component.html',
  styleUrls: ['./isc-form-field-scaling-wrapper.component.scss'],
})
export class IscFormFieldScalingWrapperComponent extends FieldWrapper implements OnInit, OnDestroy {
  field: Field;
  constructor () {
    super();
  }

  ngOnInit(): void {
    this.formModel=this.field.model;
    //this.formModel.tShirtSize='medium'; //currentSize=this.formModel.tShirtSize;
    
    this.currentSize = this.getCurrentSize();
    this.targetSize = this.currentSize;

  }
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  formModel: any = {
    tShirtSize:'',
    };
    

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  
  deploymentSizes = DeploymentOptions.sizes.icca.supported;
  currentSize;
  targetSize;

  ngOnChanges() {
    this.currentSize = this.getCurrentSize();
    this.targetSize = this.currentSize;
  }

  // Update target size during sliding, not just after change
  onSlideEvent(event: MatSliderChange) {
    if (event.value<this.currentSize) {
      event.value=this.currentSize;
    }
    this.targetSize = event.value;
    this.formModel.tShirtSize=this.deploymentSizes[event.value].size;
    this.field.formControl.patchValue(this.formModel); 
  }

  getCurrentSize() {
    return this.deploymentSizes.findIndex(
      sizeDefinition => sizeDefinition.size.toUpperCase() === this.formModel.tShirtSize.toUpperCase(),
    );
  }

  formatLabel(value: number) {
    var sizeArray = DeploymentOptions.sizes.icca.supported;
    return sizeArray[value].abbreviatedName;
  }

}
