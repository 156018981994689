import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { DeploymentObject } from 'api';
import { Observable } from 'rxjs';
import { DeploymentsService } from '../deployments.service';

@Injectable({
  providedIn: 'root',
})
export class FhirRunningGuard implements CanActivate {
  constructor(private deploymentsService: DeploymentsService) {}

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.deploymentsService.deployments) return true;
    if (route.paramMap.get('deploymentId') == '') return false;
    const currentDeployment = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      route.paramMap.get('deploymentId'),
    ) as DeploymentObject;
    if (!currentDeployment) return false;
    if (currentDeployment.deploymenttype != 'fhiraas') return false;
    if (currentDeployment.status != 'COMPLETE') return false;
    // if (currentDeployment.service_status != 'RUNNING') return false;
    return true;
  }
}
