import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from '../../../core/interfaces/subscription';
import { SharedUtils } from '../../../shared/shared-utils';
import { EPresetOptions, PaginatorConfig, TableConfig } from '@intersystems/table';
import { TableEnhancedIdService } from '../../../core/table-enhanced-id.service';

@Component({
  selector: 'app-service-overview',
  templateUrl: './service-overview.component.html',
  styleUrls: ['./service-overview.component.scss'],
})
export class ServiceOverviewComponent {
  @Input() subscription: Subscription;
  @Input() trialOfferType!: string;
  @Input() tenants!: any;
  @Input() terms!: any;

  @Output() subscribeTrial: EventEmitter<string> = new EventEmitter<string>();
  @Output() request = new EventEmitter<{ name: string; request: string }>();

  trialOffersTableConfig: TableConfig = {
    key: 'trial-offers-table',
    cssNoDataMessageClass: 'no-data-message',
    noDataMessage: 'No trial offers available',
    stickyHeaderRow: true,
    cssTRClassFromRow: TableEnhancedIdService.setTableRowIdColumn('tenantname'),
    header: {
      title: 'Trial Offers',
    },
    columns: [
      SharedUtils.getTableColumn('tenantname', 'Tenant Name'),
      SharedUtils.getTableColumn('tenantid', 'Tenant ID'),
      SharedUtils.getTableColumn('status', 'Status'),
      {
        id: 'button-cell',
        title: 'Actions',
        key: 'buttonCell',
        cellDisplay: {
          preset: EPresetOptions.BUTTONS,
          buttons: {
            buttons: [
              {
                buttonClass: 'primary',
                text: 'Start trial',
                id: 'start-trial',
                callback: (_event, row) => this.subscribeTrial.emit(row.tenantid),
                hidden: row => row.status !== 'OK',
              },
            ],
          },
        },
      },
    ],
  };

  trialTermsTableConfig: TableConfig = {
    key: 'trial-terms-table',
    cssNoDataMessageClass: 'no-data-message',
    noDataMessage: 'No trial limits available',
    stickyHeaderRow: true,
    cssTRClassFromRow: TableEnhancedIdService.setTableRowIdColumn('key'),
    header: {
      title: 'Trial Terms',
    },
    columns: [SharedUtils.getTableColumn('key', 'Name'), SharedUtils.getTableColumn('value', 'Limit')],
  };

  trialOffersTablePaginatorConfig: PaginatorConfig = {
    length: this.tenants?.tenants?.length,
    pageSize: 10,
    pageSizeOptions: [5, 10, 20, 50],
  };

  hasPricing(cloudProvider: 'aws' | 'azure' | 'google'): boolean {
    const filteredUnits = this.subscription.pricing?.units?.filter(unit => !!unit[cloudProvider]);
    return !!filteredUnits && filteredUnits.length > 0;
  }
}
