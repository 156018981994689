<mat-drawer-container class="drawer-container" autosize=true hasBackdrop="true"
  (backdropClick)="importLogDrawer.close()">

  <mat-drawer-content class="drawer-content">

    <mat-card>
      <mat-card-header>
        <mat-card-title>Import Files
          <app-info-button [htmlText]="infoObject.import.htmlText" [title]="infoObject.import.infoTitle">
          </app-info-button>
        </mat-card-title>

      </mat-card-header>

      <mat-horizontal-stepper [linear]=true #stepper (selectionChange)="selectionChange($event)">

        <mat-step label="Select type" [completed]="selectedImportType">

          <mat-card>
            <label class="radio-header">Type of file to import:<span style="color: red">*</span></label>



            <mat-radio-group aria-labelledby="radio-group-label" class="radio-group" [(ngModel)]="selectedImportType">
              <mat-radio-button class="radio-button" *ngFor="let type of importTypes" [value]="type.id"
                [id]="'import-type-' + type.id" (change)="importTypeChange($event)">
                {{type.name}}
                <app-info-button [htmlText]="type.htmlText" [title]="type.infoTitle"></app-info-button>
              </mat-radio-button>

            </mat-radio-group>
            <mat-card-actions>
              <button mat-flat-button #nextButton1 class="fr-primary button-padding" color="primary"
                id="import-step1-next" matStepperNext [disabled]="!selectedImportType">
                Next
                <mat-icon>navigate_next</mat-icon>
              </button>
            </mat-card-actions>
          </mat-card>


        </mat-step>

        <mat-step label="DDL or DML source" *ngIf="selectedImportType=='importDDL'">
          <mat-card>
            <label class="radio-header" id="platform-label">On which platform did the DDL or DML statement(s)
              originate?<span style="color: red" id="platform-span">*</span>
              <app-info-button [htmlText]="infoObject.infoVendor.htmlText" [title]="infoObject.infoVendor.infoTitle">
              </app-info-button>
            </label>


            <mat-radio-group aria-labelledby="radio-group-label" class="radio-group" [(ngModel)]="selectedDDLType"
              #ddlTypeGroup="matRadioGroup">
              <mat-radio-button class="radio-button" *ngFor="let type of ddlTypes" [value]="type.value"
                [id]="'ddl-type-' + type.value">
                {{type.name}}
              </mat-radio-button>
            </mat-radio-group>

            <mat-card-actions>
              <button id="import-ddl-type-previous" mat-stroked-button matStepperPrevious
                class="fr-tertiary button-padding" color="primary">
                <mat-icon>navigate_before</mat-icon>
                Back
              </button>
              <button id="import-ddl-type-next" mat-flat-button #nextButton1 class="fr-primary button-padding"
                color="primary" matStepperNext [disabled]="(!selectedDDLType)">
                Next
                <mat-icon>navigate_next</mat-icon>
              </button>
            </mat-card-actions>
          </mat-card>
        </mat-step>

        <mat-step [label]="(selectedImportType=='importCSV') ? 'Select file' : 'Select files'"
          [completed]="((selectedImportType=='importDDL')||((selectedImportType=='importCSV')&&(!!uploadedFiles.selectedFilename)))">

          <app-icca-uploaded-files [importType]="selectedImportType" [ddlType]="selectedDDLType"
            (fileEvents)="eventFromImportFile($event)">
          </app-icca-uploaded-files>
        </mat-step>


        <mat-step label="CSV file details" *ngIf="selectedImportType=='importCSV'">
          <app-import-csv-details (fileEvents)="eventFromImportFile($event)">

          </app-import-csv-details>
        </mat-step>

        <mat-step label="Complete">
          <mat-spinner *ngIf="loadInProgress" [diameter]="30"></mat-spinner>
          <fr-table [config]="tableConfig" [(data)]="responseData" [safeMode]="true" [paginatorConfig]="paginatorConfig"
            Id="import-results-table">
            <div class="custom-table-title" table-title-text-space>
              <app-info-button [htmlText]="infoObject.importStatus.htmlText"
                [title]="infoObject.importStatus.infoTitle">
              </app-info-button>
              <span *ngIf="selectedImportType=='importDDL'" class="additional-title-text">
                Review changes to the schema tree on the
                <button id="btn-sql-query-link" class="button-link" color="primary"
                  (click)="routeToSQLQueryTools(deploymentId)" [disabled]='loadInProgress'>SQL Query Tools</button>
                page
              </span>

            </div>
            <div class="custom-table-header-space" table-header-space>

              <button id="import-complete-back-button" mat-stroked-button class="fr-tertiary button-padding"
                color="primary" (click)="goBack()">
                <mat-icon>navigate_before</mat-icon>
                Back
              </button>
              <button id="import-complete-done-button" mat-flat-button class="fr-primary button-padding" color="primary"
                (click)="gotoStart()">
                Done
              </button>
            </div>
          </fr-table>
        </mat-step>


      </mat-horizontal-stepper>
    </mat-card>
  </mat-drawer-content>
  <mat-drawer #importLogDrawer class="right-drawer" mode="over" position="end"
    (keydown.escape)="importLogDrawer.close()">

    <app-sql-import-logs (hideDrawer)="importLogDrawer.close()">
    </app-sql-import-logs>

  </mat-drawer>
</mat-drawer-container>