<div class="wrapper">

  <app-sqlaas-external-object-storage-table [configs]="configs$ | async" [objects]="objects$ | async"
    [showField]="showField" (deleteExternalObjectConfig)="deleteExternalObjectConfig($event)"
    (addExternalObjectConfig)="addExternalObjectConfig($event)" (loadExternalObjects)="loadExternalObjects($event)"
    (rowsChanged)="rowsChanged($event)">
  </app-sqlaas-external-object-storage-table>



</div>