import { Component, OnInit, EventEmitter, Output } from '@angular/core';

import { TableConfig, PaginatorConfig, EPresetOptions, TableService } from '@intersystems/table';
import * as _ from 'lodash';
import { DeploymentObject } from 'api';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '@intersystems/notification';
import { SQLQueryService } from '../../sql-query/sql-query.service';
import { DeploymentsService } from 'src/app/deployments/deployments.service';
import { IRISAuthService } from 'src/app/deployments/icca-common/components/iris-login/iris-auth.service'; 
import { MatPaginator } from '@angular/material/paginator';
import { FormModes, IscFormModalData } from '@intersystems/isc-form';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GenericDialogComponent } from 'src/app/deployments/icca-common/dialogs/generic/generic-dialog.component'; 
import { Sort } from '@angular/material/sort';
import { IccaCommonService } from 'src/app/deployments/icca-common/services/icca-common.service';

@Component({
  selector: 'app-sql-import-logs',
  templateUrl: './sql-import-logs.component.html',
  styleUrls: ['./sql-import-logs.component.scss'],
})
export class SQLImportLogsComponent {
  @Output() hideDrawer = new EventEmitter<boolean>();

  constructor(
    private sqlQueryService: SQLQueryService,
    private notificationSvc: NotificationService,
    private route: ActivatedRoute,
    private deploymentsService: DeploymentsService,
    private irisAuthService: IRISAuthService,
    private dialog: MatDialog,
    private tableService: TableService,
    private iccaCommonService: IccaCommonService,
  ) {}

  filename:string = '';
  loadInProgress:boolean = false;


  sqlDiagColumnIds={
    ID:0,
    ACTOR:1,
    MESSAGE_TIME:2,
    SEVERITY:3,
    SQLCODE:4,
    MESSAGE:5
  };


  sqlDiagConfig: TableConfig = {
    key: 'sql-diag-table',
    header: {
      title: 'SQL diag log',
      titleTooltip: {
        text: 'SQL diag log',
      },
    },
    cssTRClass: 'tr-hotspot',
    onRowClick: (row: any) => {
      //this.queryRowSelected.emit(row.sqlStatement);
    },
    sort: {
      sortFunction: (event: Sort, data: any) =>  {
        let sortedData = data.sort((a: any, b: any) => {
          const isAsc = event.direction === "asc";
          return this.tableService.compareAlphaNumeric(
            a[event.active],
            b[event.active],
            isAsc
          );
        });
        return sortedData;
      },
      initialSortArrowDirection: {
        direction: "desc",
        doInitialSort: true,
        columnKey: 'messageTime'
      }
    },
    noDataMessage: 'No SQL diag information to display.',
    columns: [
      {
        title: 'ID',
        key: this.sqlDiagColumnIds.ID.toString(),
        id: 'ID',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.sqlDiagColumnIds.ID];
          
          },
        },
      },
      {
        title: 'Actor',
        key: this.sqlDiagColumnIds.ACTOR.toString(),
        id: 'actor',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.sqlDiagColumnIds.ACTOR];
          
          },
        },
      },
      {
        title: 'Message time',
        key: this.sqlDiagColumnIds.MESSAGE_TIME.toString(),
        id: 'messageTime',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            //return row[this.sqlDiagColumnIds.MESSAGE_TIME];
            return this.iccaCommonService.formatDate(row[this.sqlDiagColumnIds.MESSAGE_TIME]);
        
          },
        },
      },
      {
        title: 'Severity',
        key: this.sqlDiagColumnIds.SEVERITY.toString(),
        id: 'severity',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.sqlDiagColumnIds.SEVERITY];
          },
        },
      },
      {
        title: 'SQL code',
        key: this.sqlDiagColumnIds.SQLCODE.toString(),
        id: 'sqlcode',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.sqlDiagColumnIds.SQLCODE];
          },
        },
      },
      {
        title: 'Message',
        key: this.sqlDiagColumnIds.MESSAGE.toString(),
        id: 'message',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            if (row[this.sqlDiagColumnIds.MESSAGE].length > 80) {
              return row[this.sqlDiagColumnIds.MESSAGE].substring(0,79) + '...';
            } else {
              return row[this.sqlDiagColumnIds.MESSAGE];
            }
          },
        },
      },
      {
        title: 'Details',
        key: 'actionsIcons',
        sortable:false,
        cellDisplay: {
          preset: EPresetOptions.ACTIONS_ICONS,
          actionsIcons: {
            iconsOrder: ['view'],
            view: {
              id: 'view',
              tooltip: {
                text: 'View message',
              },
              callback: (event: any, row: any, col: any, rowIndex: number, paginator: MatPaginator) => {
                //this.viewFile(row.name);
                this.viewLog(row[this.sqlDiagColumnIds.MESSAGE]);
                return;
              },
            },
          },
        },
      },
      
    ],
  };

 
  
  paginatorConfig: PaginatorConfig = {
    pageSize: 10,
  };

  sqlDiagData: [] = [];
  
  getSQLDiagData(): void {
    this.loadInProgress = true;
    
    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    const deployment: DeploymentObject = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      deploymentId,
    );
    const sql =
      `SELECT ID,actor,messageTime,severity,sqlcode,message FROM %SQL_Diag.Message WHERE diagResult=(\
        SELECT TOP 1 ID from %SQL_Diag.Result WHERE $piece($piece(statement,' FILE ',2),'''',2)  = \
        '/irissys/data/uploads/${this.filename}' ORDER BY resultID DESC)`;

    this.sqlQueryService.executeSQLStatement(deployment, sql).subscribe(sqlResponse => {
      this.sqlDiagData=sqlResponse['resultSet'].data;
      this.loadInProgress=false;
    });
  }

  hide(): void {
    this.hideDrawer.emit(true);
  }

  

  viewLog(message): void {
    this.openFileViewerModal({ "title": this.filename, "log": message}); //,this.onModalSubmit.bind(this));
        
  }


  openFileViewerModal(data:any): void {
    const modalData: IscFormModalData = {
        modalTitle: data.title, 
        iscFormInputs: {
          Id: 'logViewer',
        
          FDN: {
            name: '',
            validateOn: 'change',
            sectionLayout: { showSectionHeaders: false },
            sections: [
                {
                fields: [
                  {
                    id: 'fileData',
                    key: 'fileData',
                    type: 'instructions',
                    templateOptions: {
                      label: '',
                    },
                    data: {
                      content: data.log.toString(),
                    },
                    wrappers: ['file-viewer-wrapper'],
                  },
                ]
              }
            ]
          }, 
          formModel:  data,
          mode: FormModes.EDIT,
          formConfig: {},
          buttons: [
            {
            id: 'close',
            text: 'Close',
            buttonClass: 'primary',
            type: 'button',
            callback: (clickEvent: any, button: any, formModel: any, formOptions: any, form: any) => {
                dialogRef.close();
            }
            },
          ]
        }
    }

    const dialogRef: MatDialogRef<any, any> = this.dialog.open(
      GenericDialogComponent,
        {
            panelClass: 'isc-form-modal',
            data: modalData,
            autoFocus: false
        }
    );
  }
}
