import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TenantsPageComponent } from './tenants/tenants-details/tenants-details-page/tenants-page.component';

export const routes: Routes = [
  {
    path: 'tenantdetails',
    component: TenantsPageComponent,
    pathMatch: 'full',
    data: {
      caption: 'Tenant Details',
    },
  },
  {
    path: 'tenantfiles',
    loadChildren: () => import('./tenants-files/tenants-file.module').then(m => m.TenantsFileModule),
    pathMatch: 'full',
    data: {
      caption: 'Files',
    },
  },
  {
    path: 'usagerecords',

    loadChildren: () => import('./usagerecords/usagerecords.module').then(m => m.UsageRecordsModule),
    pathMatch: 'full',
    data: {
      caption: 'Billing',
    },
  },
  {
    path: '',
    redirectTo: 'tenantdetails',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TenantsRoutingModule {}
