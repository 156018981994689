import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

import { Observable, of, Subject } from 'rxjs';
import { catchError, pluck, map, tap } from 'rxjs/operators';

import { NotificationService } from '@intersystems/notification';

import { NOTIFICATION_TYPE } from '@intersystems/notification';
import { ImportTableConfig } from '../../icca-common/model/sql-response'; 
import { environment } from 'src/environments/environment';


import { AnyRecord } from 'dns';
import { env } from 'process';
import { ActivatedRoute } from '@angular/router';
import { table } from 'console';
import { DeploymentObject } from 'projects/api/src';
import { FDN, Field, IscFormModalData } from '@intersystems/isc-form';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GenericDialogComponent } from '../../icca-common/dialogs/generic/generic-dialog.component'; 
import { EPresetOptions } from '@intersystems/table';
import { AbstractControl } from '@angular/forms';
import { IRISAuthService } from '../../icca-common/components/iris-login/iris-auth.service'; 
import { IrisConnection } from '../../icca-common/model/connection';
import { IccaCommonService } from '../../icca-common/services/icca-common.service';

export enum FormModes {
  VIEW = "view",
  EDIT = "edit"
}

@Injectable({ providedIn: 'root' })
export class ImportService {
  
  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private irisAuthService: IRISAuthService,
    private iccaCommonService: IccaCommonService,
  ) {}
  
  connectionInfo:IrisConnection;

  httpOptions = {
    headers: new HttpHeaders(
      { //'content-type': 'application/json',
      }
    )
  };
    
  //

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`,NOTIFICATION_TYPE.ALERT);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a DeploymentService message with the NotificationService */
  private log(message: string, type:NOTIFICATION_TYPE) {
    this.notificationService.show(type,`ImportService: ${message}`,7000);
  }

  
/** Load CSV file into server */
  loadCSV(deployment:DeploymentObject, filename: string, tableInfo:ImportTableConfig): Observable<any[]> {
    const url = `${this.iccaCommonService.getAPIURL(deployment.deploymenttype)}/sql/query`;
    
    var httpOptions = {
      headers: new HttpHeaders(
        { 
          'accept': 'text/plain; charset=utf-8',
          'content-type': 'application/json'      
        }),
    };
    var body:string = this.getLOADDATAStatement(filename,tableInfo);

    var data:any = {
      deploymentId: deployment.deploymentid,
      connection:this.irisAuthService.getIRISConnectionInfo(deployment.deploymentid, deployment.deploymenttype),
      "sql": {
        query:body,
        maxRows:"100000",
        maxLength:"4000000"     
      }
    };
    data.connection.networkTimeout="10000"; //lower network timeout to 10s for CSV load because of autorefresh

    return this.http.post(url,JSON.stringify(data),httpOptions)
    .pipe(
      catchError(this.handleError<any>(`loadCSV filename=${filename}`, []))
    );
  }
  getLOADDATAStatement(filename: string, tableInfo:ImportTableConfig): string {
    var body:string;
    var colListforSQL:string = '(';
    var colINTOListforSQL:string = '(';
    tableInfo.colList.forEach(col => {
      colListforSQL=colListforSQL + ` ${col["display"].replace(/\(/g, " ( ").replace(/\)/g, " )")} ,`;    
      colINTOListforSQL=colINTOListforSQL + ` ${col["name"]} ,`;    
    })
    colListforSQL = colListforSQL.substring(0,colListforSQL.length-1) + ') ';
    colINTOListforSQL = colINTOListforSQL.substring(0,colINTOListforSQL.length-1) + ') ';
    
    body=`LOAD DATA FROM FILE \u0027\u0027/irissys/data/uploads/${filename}\u0027\u0027 `;
    
    if (!tableInfo.useHeaders) {
      body=body + 'COLUMNS ' + colListforSQL.toUpperCase();
    }

    if (tableInfo.hasHeaders) {
      body = body + `INTO ${tableInfo.table.toUpperCase().replace(/\./g, " . ")} `;
      body=body + "USING {\"from\":{\"file\":";
      body=body + "{\"header\":\"1\"";
      body=body + `,\"skip\":\"${tableInfo.skipRows}\"`;

    } else {
      body = body + `INTO ${tableInfo.table.toUpperCase().replace(/\./g, " . ")} `;
      body=body + colINTOListforSQL.toUpperCase();
      body=body + "USING {\"from\":{\"file\":";
      body=body + "{\"header\":\"0\"";
      body=body + `,\"skip\":\"${tableInfo.skipRows}\"`;
}  
    body=body + `,\"columnseparator\":\"${tableInfo.colSeparator}\"` ;
    body=body + `,\"lineseparator\":\"${tableInfo.lineSeparator}\"` ;

    //close from and file in USING section
    body=body + "}}}";

    return body;
  }
}