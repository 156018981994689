import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeploymentOptions } from '../../../deployments/data/deployment-options';
import { FDN, FormButton, VALIDATORS } from '@intersystems/isc-form';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { NUMBERS } from 'regex';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-service-settings',
  templateUrl: './service-settings.component.html',
  styleUrls: ['./service-settings.component.scss'],
})
export class ServiceSettingsComponent implements OnInit, OnDestroy {
  public deploymentOptions = { ...DeploymentOptions };

  @Input() config: Partial<{
    type: string;
    region: string;
    coresFG: {
      requests: string;
      cores: { name: string | number; value: string | number };
    };
    diskSize: string | number;
    requests: string;
    enableSqlbuilder: boolean;
    enableHA: boolean;
    enableIML: boolean;
    tShirtSize: string; //"small" | "medium" | "large" | "xLarge" ;
  }> = {
    type: undefined,
    region: undefined,
  };

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  public fhiraasFDN: FDN = {
    name: '',
    description: '',
    validateOn: 'change',
    sectionLayout: { showSectionHeaders: false },
    sections: [
      {
        fields: [
          {
            key: 'coresFG',
            className: 'formSection',
            hooks: {
              onInit: field => {
                field.form
                  .get('coresFG.requests')
                  .valueChanges.pipe(
                    tap(changes => {
                      if (changes) {
                        field.form.get('coresFG.cores').patchValue(this.setFhirCores(changes));
                      }
                    }),
                    takeUntil(this._unsubscribeAll),
                  )
                  .subscribe();
              },
            },
            fieldGroup: [
              {
                id: 'requests',
                key: 'requests',
                type: 'input',
                templateOptions: {
                  label: 'Workload of messages per minute, up to',
                  pattern: NUMBERS,
                },
                overrideValidatorMessage: {
                  [VALIDATORS.PATTERN]: 'Value must be a number',
                },
                validators: {
                  ['max']: {
                    expression: (control: AbstractControl) => !control.value || control.value <= 52000,
                    message: () => 'Maximum workload per minute is 52.000',
                  },
                },
              },
              {
                id: 'cores',
                key: 'cores',
                type: 'select',
                data: {
                  displayField: 'name',
                  uniqueValueField: 'value',
                  optionIdField: 'name',
                },
                templateOptions: {
                  label: 'Cores',
                  required: true,
                  options: [],
                  onlySaveUniqueValueField: true,
                },
              },
            ],
          },
          {
            id: 'diskSize',
            key: 'diskSize',
            type: 'input',
            templateOptions: {
              label: 'Disk size, GB',
              required: true,
              pattern: NUMBERS,
            },
            overrideValidatorMessage: {
              [VALIDATORS.PATTERN]: 'Value must be a number',
            },
            validators: {
              ['min']: {
                expression: (control: AbstractControl) => !control.value || control.value >= 20,
                message: () => 'Minimum disk size is 20 GB.',
              },
              ['max']: {
                expression: (control: AbstractControl) => !control.value || control.value <= 400,
                message: () => 'Maximum disk size is 400 GB.',
              },
            },
          },
          {
            id: 'enableHA',
            key: 'enableHA',
            type: 'checkbox',
            templateOptions: {
              label: 'Highly Available (HA) Configuration',
            },
          },
          {
            id: 'enableSqlbuilder',
            key: 'enableSqlbuilder',
            type: 'checkbox',
            templateOptions: {
              label: 'Enable SQL Builder',
            },
          },
        ],
      },
    ],
  };

  public hmtsFDN = {
    name: '',
    description: '',
    validateOn: 'change',
    sectionLayout: { showSectionHeaders: false },
    sections: [
      {
        className: 'formSection',
        fields: [
          {
            id: 'requests',
            key: 'requests',
            type: 'input',
            templateOptions: {
              label: 'Number of requests',
              required: true,
              pattern: NUMBERS,
            },
            overrideValidatorMessage: {
              [VALIDATORS.PATTERN]: 'Value must be a number',
            },
          },
          {
            id: 'unit',
            key: 'unit',
            type: 'select',
            data: {
              displayField: 'name',
              uniqueValueField: 'value',
              optionIdField: 'name',
              onlySaveUniqueValueField: true,
            },
            templateOptions: {
              label: 'Unit, per',
              required: true,
              options: [
                {
                  name: 'hour',
                  value: 'hour',
                },
                {
                  name: 'day',
                  value: 'day',
                },
                {
                  name: 'month',
                  value: 'month',
                },
              ],
            },
          },
        ],
      },
    ],
  };

  public hccFDN = {
    name: '',
    description: '',
    validateOn: 'change',
    sectionLayout: { showSectionHeaders: false },
    sections: [
      {
        className: 'formSection',
        fields: [
          {
            id: 'requests',
            key: 'requests',
            type: 'input',
            templateOptions: {
              label: 'Number of requests',
              required: true,
              pattern: NUMBERS,
            },
            overrideValidatorMessage: {
              [VALIDATORS.PATTERN]: 'Value must be a number',
            },
            validators: {
              ['max']: {
                expression: (control: AbstractControl) => !control.value || control.value <= 2400000000,
                message: () => 'Maximum amount of requests is 2.4 billion per month.',
              },
            },
          },
          {
            id: 'unit',
            key: 'unit',
            type: 'select',
            data: {
              displayField: 'name',
              uniqueValueField: 'value',
              optionIdField: 'name',
              onlySaveUniqueValueField: true,
            },
            templateOptions: {
              label: 'Unit, per',
              required: true,
              options: [
                {
                  name: 'hour',
                  value: 'hour',
                },
                {
                  name: 'day',
                  value: 'day',
                },
                {
                  name: 'month',
                  value: 'month',
                },
              ],
            },
          },
        ],
      },
    ],
  };

  public sqlaasFDN: FDN = {
    name: '',
    description: '',
    validateOn: 'change',
    sectionLayout: { showSectionHeaders: false },
    sections: [
      {
        fields: [
          {
              id: 'tShirtSize',
              key: 'tShirtSize',
              type: 'select',
              data: {
                displayField: 'name',
                uniqueValueField: 'value',
                optionIdField: 'name',
              },
              templateOptions: {
                label: 'Deployment size',
                required: true,
                options: this.deploymentOptions.sizes.icca.supported.map(size => ({ value: size.size, name: `${size.name} - ${size.cores} cores / ${size.storage}`, diskSize:size.storageInGB, cores:size.cores })),
          
              },
          },
          {
            id: 'enableIML',
            key: 'enableIML',
            type: 'checkbox',
            templateOptions: {
              label: 'Enable IntegratedML',
            },
          },
        ],
      },
    ],
  };


  buttons: FormButton[] = [
    {
      id: 'btn-confirm',
      text: 'Confirm',
      buttonClass: 'primary',
      type: 'submit',
      callback: (clickEvent: any, button: any, formModel: any, _options, form) => {
        this.dialogRef.close(formModel);
      },
      disabledIfFormInvalid: true,
    },
    {
      id: 'btn-back',
      text: 'Back to offers',
      buttonClass: 'secondary',
      type: 'reset',
      callback: () => (this.config.type = undefined),
    },
  ];

  constructor(public dialogRef: MatDialogRef<ServiceSettingsComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.fhiraasFDN.sections[0].fields[0].fieldGroup.find(item => item.id == 'cores').templateOptions.options =
      this.getFhirCores();
  }

  configure(type: 'fhiraas' | 'hmts' | 'hcc' | 'sqlaas'): void {
    this.config.type = type;
    if (type=='fhiraas') {
      this.config.enableHA=false;
      this.config.enableSqlbuilder=false;
    }
    if (type='sqlaas') {
      this.config.enableIML=false;
    }
  }

  getFhirCores(): { name: string; value: string }[] {
    let result;
    result = [...this.deploymentOptions.sizes.common];
    return result.map(i => ({ name: i.name + ' (' + i.cores.toString() + ')', value: i.cores.toString() }));
  }
  
  setFhirCores(messages): string {
    if (messages <= 1700) {
      return '2';
    }

    if (messages <= 4000) {
      return '8';
    }

    if (messages <= 14000) {
      return '16';
    }

    if (messages <= 27000) {
      return '32';
    }

    if (messages <= 52000) {
      return '64'; 
    }
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  showOffer(offerType):boolean {
    //if offer is explicitly hidden, hide it.
    if (environment['HIDDEN_OFFERS']) {
      if (environment['HIDDEN_OFFERS'].includes(offerType)) {
        return false;
      }
    }
    //if VISIBLE_OFFERS array is defined, then only show what's in VISIBLE_OFFERS    
    return environment['VISIBLE_OFFERS'] ? environment['VISIBLE_OFFERS'].includes(offerType) : true;
  }
}
