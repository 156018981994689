import { JsonpClientBackend } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FDN, Field } from '@intersystems/isc-form';
import { EPresetOptions, PaginatorConfig, TableConfig } from '@intersystems/table';
import { FieldWrapper } from '@ngx-formly/core';
import { TableEnhancedIdService } from 'src/app/core/table-enhanced-id.service';
import { ImportService } from 'src/app/deployments/sqlaas/import/import.service';

import { BehaviorSubject } from 'rxjs';
import { CANVASJS, LazyCanvasJS } from 'src/app/app.component';
import { SQLQueryService } from 'src/app/deployments/sqlaas/sql-query/sql-query.service';
import { DeploymentObject } from 'api';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-isc-form-iml-chart-wrapper',
  templateUrl: './isc-form-field-iml-chart-wrapper.component.html',
  styleUrls: ['./isc-form-field-iml-chart-wrapper.component.scss'],
})
export class IscFormFieldIMLChartWrapperComponent extends FieldWrapper implements OnInit {
  field: Field;
  fileContents;

  constructor(
    @Inject(CANVASJS) 
    private canvasJS: LazyCanvasJS,
    private sqlQueryService: SQLQueryService,
    private importService: ImportService,
    ) {super();}
  
  
    interval = 6000000; // measurements interval in milliseconds
  
    reportData: {min: any, max:any, data:any} = {min:'', max:'', data:[]};
    _comparisonChart: any;
    
    
    ngOnInit(): void {
      this.renderCharts();
    }
  
    renderCharts(): void {
      this.getComparisonData();
      
    }
  
    getComparisonData() {
      
      this.sqlQueryService.executeSQLStatement(this.field.data['content'].deployment, this.field.data['content'].sql).pipe(take(1)).subscribe(sqlResponse => {
        //console.log(sqlResponse['resultSet'].data);
        //x is prediction, y is actual
        //should be in format: [{x:1.10, y:1.13}]
        this.reportData.min=0;
        this.reportData.max='';
        this.reportData.data = sqlResponse['resultSet'].data.map(val=> {
          const valX=+val[0];
          const valY=+val[1];
          if (valX<+this.reportData?.min) this.reportData.min=valX;
          if (valX>+this.reportData?.max) this.reportData.max=valX+1;
          if (valY<+this.reportData?.min) this.reportData.min=valY;
          if (valY>+this.reportData?.max) this.reportData.max=valY+1;
          return ({x:(+val[0]).toFixed(2), y:+val[1]});
          });
          this.comparisonChart();
      });
  
      }
    comparisonChart(): void {
      //const messagesPerPeriod = this.reportData.dataPoints.map(val => ({ x: val.date, y: val.messages }));
  
      this.canvasJS.then(canvasJS => {
        this._comparisonChart = new canvasJS.Chart('comparisonChartContainer', {
          title: {
            text: "Prediction vs Actual"
          },
          axisX: {
            title: "Prediction",
            minimum: this.reportData.min,
            maximum: this.reportData.max,
          },
          axisY: {
            title: "Actual",
            minimum: this.reportData.min,
            maximum: this.reportData.max,
          },
          
          toolTip: {
            shared: true
          },
          
          legend: {
            cursor: "pointer",
            verticalAlign: "top",
            horizontalAlign: "center",
            dockInsidePlotArea: true,
          },
          
          data: [
          {
            type: "scatter",
            dataPoints: this.reportData.data,
            toolTipContent: "<b>Prediction: </b>{x} <br/><b>Actual: </b>{y}",
          },
          ]
        });
        //console.log(this._comparisonChart);
        this._comparisonChart.render();
      });
    }
    toogleDataSeries(e){
      if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
        e.dataSeries.visible = false;
      } else{
        e.dataSeries.visible = true;
      }
      this._comparisonChart.render();
    }
  
    syncHandler(e) {
      const charts = [];
      charts.length === 0 ? charts.push(this._comparisonChart) : null;
  
      for (let i = 0; i < charts.length; i++) {
        const chart = charts[i];
  
        if (!chart.options.axisX) chart.options.axisX = {};
  
        if (!chart.options.axisY) chart.options.axisY = {};
  
        if (e.trigger === 'reset') {
          chart.options.axisX.viewportMinimum = chart.options.axisX.viewportMaximum = null;
          chart.options.axisY.viewportMinimum = chart.options.axisY.viewportMaximum = null;
  
          chart.render();
        } else if (chart !== e.chart) {
          chart.options.axisX.viewportMinimum = e.axisX[0].viewportMinimum;
          chart.options.axisX.viewportMaximum = e.axisX[0].viewportMaximum;
  
          chart.options.axisY.viewportMinimum = e.axisY[0].viewportMinimum;
          chart.options.axisY.viewportMaximum = e.axisY[0].viewportMaximum;
  
          chart.render();
        }
      }
    }
  
    
    ngOnDestroy() {
      this.canvasJS = null;
    }
  }

  
  





