<fr-table
  [config]="tableConfig"
  [data]="filteredDeployments$ | async"
  Id="deployments-table"
  [paginatorConfig]="paginatorConfig"
>
  <div above-table-body-space>
    <app-loading-spinner *ngIf="!deployments"></app-loading-spinner>

    <div class="filter" *ngIf="deployments">
      <div class="filterForm">
        <fr-isc-form Id="filterForm" [FDN]="filterFDN" [formModel]="filter"></fr-isc-form>
      </div>
      <div style="margin-top: 20px">
        <button mat-button id="btn-clear-fiters" (click)="filter = {}">
          <mat-icon svgIcon="filter-reset"></mat-icon>
          Clear Filters
        </button>
        <button mat-button id="btn-load-deleted" (click)="loadDeleted.emit()">
          <mat-icon svgIcon="delete-outline"></mat-icon>
          Load Deleted
        </button>
      </div>
    </div>
  </div>
</fr-table>
