import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TopbarControlService } from '@intersystems/header';
import { ActivatedRoute, Router } from '@angular/router';
import { delay, repeatWhen, takeWhile, concatMap } from 'rxjs/operators';
import { IccaUploadedFilesComponent } from '../../icca-common/components/uploaded-files/uploaded-files.component';
import { MatStepper } from '@angular/material/stepper';
import { Column, EPresetOptions, PaginatorConfig, TableConfig } from '@intersystems/table';
import { ImportResponse } from '../../icca-common/model/sql-response'; 
import { ImportCsvDetailsComponent } from './import-csv-details/import-csv-details.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { TableEnhancedIdService } from 'src/app/core/table-enhanced-id.service';
import { SQLQueryService } from '../sql-query/sql-query.service';
import { Observable, defer, of, Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { DeploymentObject } from 'api';
import { FileHandlerService } from '../../icca-common/services/file-handler.service';
import { SQLImportLogsComponent } from './sql-import-logs/sql-import-logs.component';
import { MatDrawer } from '@angular/material/sidenav';
import { DeploymentsService } from '../../deployments.service';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
})
export class ImportComponent implements OnInit, OnDestroy{
  @ViewChild(IccaUploadedFilesComponent) public uploadedFiles: IccaUploadedFilesComponent;
  @ViewChild(ImportCsvDetailsComponent) public importCSVDetailsComponent: ImportCsvDetailsComponent;
  @ViewChild('stepper') private stepper: MatStepper;
  @ViewChild(SQLImportLogsComponent) private sqlImportLogsComponent:SQLImportLogsComponent;
  @ViewChild('importLogDrawer') importLogDrawer: MatDrawer;
 
  ngOnInit() {
    this.deploymentId = this.route.snapshot.paramMap.get('deploymentId');
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.sub.unsubscribe();
 }
  constructor(
    private topbarControlService: TopbarControlService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private sqlQueryService: SQLQueryService,
    private fileHandlerService: FileHandlerService,
    private deploymentsService: DeploymentsService,
  ) {}

  private sub = new Subscription();
  fileData: any;
  deploymentId: string = '';
  responseData: ImportResponse[] = [];
  loadInProgress:boolean=false;
  selectedImportType: string;
  importTypes = [
    {
      id: 'importDDL',
      name: 'DDL or DML statement(s)',
      infoTitle: 'Import DDL and DML',
      htmlText: "Import DDL and DML statements from InterSystems and other vendors; for details see \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/Doc.View.cls?KEY=GSQL_IMPORT' target='_blank'>Importing SQL Code</a>, the \
      <a href='https://docs.intersystems.com/irislatest/csp/documatic/%25CSP.Documatic.cls?LIBRARY=%25SYS&CLASSNAME=%25SYSTEM.SQL.Schema#ImportDDL' target='_blank'>%SYSTEM.SQL.Schema.ImportDDL()</a> class method, and \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/Doc.View.cls?KEY=GSQL_TABLES#GSQL_tables_via_ddl' target='_blank'>Defining a Table Using DDL</a>. \
      You can also import \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GSQL_privileges' target='_blank'>user and role management commands</a>."
    },
    {
      id: 'importCSV',
      name: 'CSV data',
      infoTitle: 'Import from CSV',
      htmlText: "Import data from files in CSV format into tables you have previously created with DDL statements; for details see \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=RSQL_loaddata' target='_blank'>LOAD DATA</a>."
    },
  ];
  selectedDDLType: string = 'IRIS';
  ddlTypes= [
    {name: 'InterSystems IRIS',value:'IRIS'},
    {name: 'InterSystems Caché',value:'CACHE'},
    {name: 'MySQL',value:'MySQL'},
    {name: 'Microsoft SQL Server',value:'MSSQLServer'},
    {name: 'Oracle',value:'Oracle'},
    {name: 'Sybase',value:'Sybase'},
    {name: 'Informix',value:'Informix'},
    {name: 'Interbase',value:'Interbase'},
  ];

  infoObject = {
    import: {
      infoTitle: 'Import files',
      htmlText: "Use this page to import files containing: \
      <ul> \
      <li>DDL statements, to \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GSQL_tables' target='_blank'>define and modify schemas and tables</a></li> \
      <li>CSV data, to \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=RSQL_loaddata' target='_blank'>load data</a> \
      into tables</li> \
      <li>DML statements, to \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GSQL_modify' target='_blank'>insert, delete, modify,</a> and \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GSQL_queries' target='_blank'>query</a> \
      data in tables</li> \
      <li>User and role management commands, to \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GSQL_privileges' target='_blank'>create, alter and delete users and create and delete roles</a></li> \
      </ul> <br> \
      Bear in mind that to load data, you must first import the DDL statement that creates the target table.\
      <br><br>\
      If the files you want to import are not listed when you reach the Select files step, go to the Manage Files page to add them."
    },
    infoVendor: {
      infoTitle: 'SQL Formats',
      htmlText: "Cloud SQL can read, convert, and execute DDL and DML in the SQL formats of other vendors. \
      Select the origin of the statements you are importing. For details, see \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/Doc.View.cls?KEY=GSQL_IMPORT#GSQL_import_noncache' target='_blank'>Code Migration: Importing non-InterSystems SQL</a>."
    },
    importStatus: {
      infoTitle: 'Import status',
      htmlText: "To troubleshoot unexpected results when importing DDL or DML, see the \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=RSQL_COMMANDS' target='_blank'>reference pages</a> \
      for the commands involved, for example \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=RSQL_createtable' target='_blank'>CREATE TABLE</a>. \
      <br><br>To troubleshoot when loading data, view or download the import log by using the icons in the <b>Log File</b> column \
      and see \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=RSQL_loaddata#RSQL_loaddata_logs' target='_blank'>View Diagnostic Logs of Loaded Data</a>. \
      <br><br>You can also \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/Doc.View.cls?KEY=RERR_sql' target='_blank'>look up SQL error messages</a> \
      by error code."
    }
  };

  selectedTabIndex = 0;

  eventFromUploadFile($event) {
    if ($event == 'uploaded') {
      //refresh files list on uploaded files component
      this.uploadedFiles.getFiles();
      this.stepper.next();
    }
  }

  eventFromImportFile($event) {
    if ($event == 'imported') {
      this.uploadedFiles.getFiles();
      this.stepper.next();
      if (this.selectedImportType == 'importDDL') {
        this.responseData = this.uploadedFiles.response;
      } else {
        this.responseData = this.importCSVDetailsComponent.response;
        var response=this.responseData[0];
        if (response['output']=='Load in progress') {
          //if load is still in progress, query in to get status and resultid for most recent load with that file
          //then go into loop checking on status
          this.loadInProgress=true;
          var resultId='';
          this.sub.add(this.sqlQueryService.executeSQLStatement(response['inProgressData']['deployment'],response['inProgressData']['query'])
          .subscribe(sqlResponse => {
            this.responseData=[];
            //first time through query is based on filename used, 
            //after that it will be based on the resultid returned from the 1st query
            resultId=sqlResponse['resultSet'].data[0][0];
              //response['inProgressData']['query']=`SELECT TOP 1 resultID,status,inputRecordCount,errorCount FROM \
              //%SQL_DIAG.Result WHERE resultID=${resultId}`;
            var status=sqlResponse['resultSet'].data[0][1];
            if (status!='In Progress') {
              status = 'Rows Updated: ' + sqlResponse['resultSet'].data[0][2];
              this.responseData.push({
                fileName: response['fileName'],
                output: status,
                table: response['table'],
              });
              this.loadInProgress=false;
              return;
            } else {
              const date = new Date();
              date.toLocaleString();
              status=status + ' at ' + date;
            }
            this.responseData.push({
                fileName: response['fileName'],
                output: status,
                table: response['table'],
              });
            var query=`SELECT TOP 1 resultID,status,inputRecordCount,errorCount FROM %SQL_DIAG.Result WHERE resultID=${resultId}`;  
            this.sub.add(this.sqlQueryService.executeSQLStatement(response['inProgressData']['deployment'],query)
            .pipe(
              repeatWhen(obs => obs.pipe(delay(5000))),
              takeWhile(
                sqlResponse => {
                  this.responseData=[];
                  var status=sqlResponse['resultSet'].data[0][1];
                  if (status!='In Progress') {
                    status = 'Rows Updated: ' + sqlResponse['resultSet'].data[0][2];
                    this.loadInProgress=false;
                  } else {
                    const date = new Date();
                    date.toLocaleString();
                    status=status + ' at ' + date;
                  }
                  this.responseData.push({
                      fileName: response['fileName'],
                      output: status,
                      table: response['table'],
                    });
                  return (sqlResponse['resultSet'].data[0][1]=='In Progress')
                }
              )
            ).subscribe());   
          }));
        }
      }
    }
  }

  tableConfig: TableConfig = {
    key: 'import-status-table',
    header: {
      title: 'Import Status',
    },
    cssTableClass: 'table-class',
    cssTRClass: 'table-row',
    cssTRClassFromRow: TableEnhancedIdService.setTableRowIdColumn('fileName'),
    noDataMessage: 'No data to display.',
    columns: [
      {
        title: 'File',
        key: 'fileName',
        id: 'fileName',
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row.fileName;
          },
        },
      },
      {
        title: "Table",
        key: "table",
        id: "table",
        cssTHClass: "",
        cssTDClass: "",
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row.table;
          }
        },
      },
      {
        title: 'Result',
        key: 'output',
        id: 'output',
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row.output;
          },
        },
        cssTDClass: 'preserve-whitespace',
      },
      {
        title: 'Log File',
        key: 'actionsIcons',
        cellDisplay: {
          preset: EPresetOptions.ACTIONS_ICONS,
          actionsIcons: {
            iconsOrder: ['view', 'download'],
            view: {
              id: 'view',
              tooltip: {
                text: 'View log',
              },
              disabled: (row: any, column: Column) => {
                //update so enabled if error log file exists
                return ((row.output=='File imported'));
              },
              callback: (event: any, row: any, col: any, rowIndex: number, paginator: MatPaginator) => {
                //this.viewFile(row.name);
                this.viewErrorLogFile(row.fileName);
                return;
              },
            },
            download: {
              id: 'download',
              tooltip: {
                text: 'Download log file',
              },
              customSvgIcon: 'download',
              disabled: (row: any, column: Column) => {
                //update so enabled if error log file exists
                return ((row.output=='File imported') || (this.selectedImportType=="importCSV"));
              },
              callback: (event: any, row: any, col: any, rowIndex: number, paginator: MatPaginator) => {
                this.downloadErrorLogFile(row.fileName);
                return;
              },
            },
          },
        },
      },
    ],
  };
  paginatorConfig: PaginatorConfig = {
    pageSize: 10,
  };

  importTypeChange($event): void {
    //when type changes, remove any selected files
    this.uploadedFiles.selectedFilename='';
    this.uploadedFiles.rowsChecked=[];
    this.uploadedFiles.files?.forEach((item: any) => (item.select = false));
  }

  selectionChange($event): void {
    switch ($event.selectedIndex) {
      case 1:
        this.uploadedFiles.updateLabel();
        break;
      case 2:
        if (this.selectedImportType == 'importCSV') {
          this.importCSVDetailsComponent.filename = this.uploadedFiles.selectedFilename;
        }
        break;
      case 3:
        if (this.selectedImportType=='importCSV') { 
          this.tableConfig.columns[1].cssTDClass="";
          this.tableConfig.columns[1].cssTHClass="";
        } else {
          this.tableConfig.columns[1].cssTDClass="hideTableData";
          this.tableConfig.columns[1].cssTHClass="hideTableHeader";
       
        }
        break;
    }

  }

  goBack() {

    if (this.selectedImportType == 'importDDL')  {
      this.stepper.selectedIndex=this.stepper.selectedIndex-1;
    } else {
      this.stepper.selectedIndex=this.stepper.selectedIndex-2;
    }
  }
  gotoStart() {
    this.stepper.selectedIndex=0;
 }

  downloadErrorLogFile(filename): void {
    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    const deployment: DeploymentObject = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      deploymentId,
    );
    filename=filename.replace(/\.[^/.]+$/, "") + "_Errors.log";
    this.sub.add(this.fileHandlerService.getFile('sqlaas',deployment.deploymentid, deployment.region, filename, 'errors', 'download').subscribe());
  }
  
  viewErrorLogFile(filename): void {
    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    const deployment: DeploymentObject = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      deploymentId,
    );
   
    
    if (this.selectedImportType=='importDDL') {
      filename=filename.replace(/\.[^/.]+$/, "") + "_Errors.log";

      this.sub.add(this.fileHandlerService.getFile('sqlaas', deployment.deploymentid, deployment.region, filename,'errors', 'view').subscribe(fileData => {
        const reader = new FileReader();
        reader.onloadend = e => {
          this.fileData = reader.result;
          this.fileHandlerService.openFileViewerModal({ filename: filename, fileData: fileData }); //,this.onModalSubmit.bind(this));
        };
        reader.readAsText(fileData.body);
      }));
    }

    if (this.selectedImportType=='importCSV') {
      this.sqlImportLogsComponent.filename=filename;
      this.sqlImportLogsComponent.getSQLDiagData();
      this.importLogDrawer.open();
      
    }


  }
  routeToSQLQueryTools(deploymentId) {
      this.router.navigate(['/deployments', deploymentId, 'sqlquery']);
  }
}
