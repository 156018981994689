import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from '../../shared/services/shared.service';

@Injectable({
  providedIn: 'root',
})
export class LiveGuard implements CanActivate {
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return SharedService.isLive();
  }
}
