import { Component, Inject, ViewEncapsulation } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { from } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LazySwaggerUI, SWAGGER } from '../../app.component';

@Component({
  selector: 'app-portal-api',
  templateUrl: './portal-api.component.html',
  styleUrls: ['./portal-api.component.scss'],
  encapsulation: ViewEncapsulation.None, // Needed for swagger-ui styles
})
export class PortalApiComponent {
  ui: any = null;
  swaggerConfig = {
    url: '/assets/openapi/open-api-temp.yml',
    dom_id: '#swagger-ui',
    enableCORS: true,
    layout: 'BaseLayout',
    displayRequestDuration: true,
    onComplete: () =>
      from(Auth.currentSession())
        .pipe(
          tap(session => this.ui.preauthorizeApiKey('bearerAuth', session.getIdToken().getJwtToken())),
          take(1),
        )
        .subscribe(),
    plugins: [
      // Custom plugin that replaces the server list with the current url
      function () {
        return {
          statePlugins: {
            spec: {
              wrapActions: {
                updateJsonSpec: function (oriAction, system) {
                  return spec => {
                    spec.servers = [{ url: environment.API_URL }];
                    return oriAction(spec);
                  };
                },
              },
            },
          },
        };
      },
    ],
  };

  constructor(@Inject(SWAGGER) private swagger: LazySwaggerUI) {
    this.swagger.then(swaggerUI => {
      swaggerUI.plugins.DownloadUrl;
      this.ui = swaggerUI(this.swaggerConfig);
    });
  }
}
