import { Component, Input, ViewChild } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  FDN,
  Section,
  Field,
  IscFormModalData,
  IscFormModalComponent,
  FIELD_TYPES,
  VALIDATORS,
  Option,
  Link,
} from '@intersystems/isc-form';
import { GenericDialogComponent } from 'src/app/deployments/icca-common/dialogs/generic/generic-dialog.component'; 
import { environment } from 'src/environments/environment';

export enum FormModes {
  VIEW = 'view',
  EDIT = 'edit',
}

@Component({
  selector: 'app-info-button',
  templateUrl: './info-button.component.html',
  styleUrls: ['./info-button.component.scss'],
})
export class InfoButtonComponent {
  constructor(private dialog: MatDialog) {}
  @Input() title = '';
  @Input() infoText = '';
  @Input() linkArray: (string | Link)[] = [];
  @Input() htmlText: string = '';
  @Input() buttonName = 'btn-info';
  @Input() class='';
  @Input() tabIndex='0';
  
  //if using appcues in this environment, hide the info boxes
  appcues=!!environment['appcues'];

  infoClick() {
    if (this.htmlText) {
      this.openInfoFieldModalHtml(this.title, this.htmlText);
    } else {
      this.openInfoFieldModal(this.title, this.linkArray);
    }
  }

  //openFieldDemoModal(title: string, type: FIELD_TYPES) {
  openInfoFieldModal(title: string, linkArray: (string | Link)[]) {
    const modalData: IscFormModalData = {
      modalTitle: '',
      iscFormInputs: {
        Id: '',
        FDN: {
          name: '',
          description: '',
          validateOn: 'change',
          autosave: {
            trigger: 'timeInterval',
            interval: 10000,
            maxIntervals: 12,
          },
          sectionLayout: {
            showSectionHeaders: false,
          },
          sections: [
            {
              name: '',
              fieldGroup: [],
              hideExpression: '',
              hide: false,
              fields: [
                {
                  hide: false,
                  type: 'instructions',
                  key: 'sample-instruction',
                  viewOnly: true,
                  data: {
                    //content: infoText,

                    linkArray: linkArray,

                    //content: "aaaaaaa" //`${this.infoText}`
                  },
                },
                {
                  id: 'infoButtonForm',
                  key: 'infoButtonForm',
                  //type: 'instructions',
                  type: 'input',
                  templateOptions: {
                    //label: 'Import from S3 bucket',
                  },
                  data: {
                    displayField: 'name',
                    uniqueValueField: 'name',
                    optionIdField: 'name',
                  },
                  wrappers: ['info-button-wrapper'],
                },


              ],
            },
          ],
        },
        formModel: {},
        mode: FormModes.EDIT,
        formConfig: {},
      }
    };

    modalData.iscFormInputs.Id = 'info-form';

    modalData.modalTitle = title;

   
    const dialogRef: MatDialogRef<any, any> = this.dialog.open(IscFormModalComponent, {
      autoFocus: true, //![FIELD_TYPES.BINARY_CHECKBOX].includes(type),
      panelClass: 'isc-form-modal',
      data: modalData,
    });
  }

  openInfoFieldModalHtml(title: string, htmlText) {
      const modalData: IscFormModalData = {
        modalTitle: title,
        iscFormInputs: {
        Id: 'info-button-form',
        formModel: {
          htmlText: htmlText
        },
        FDN: {
          name: '',
          description: '',
          validateOn: 'change',
          autosave: {
            trigger: 'timeInterval',
            interval: 10000,
            maxIntervals: 12,
          },
          sectionLayout: {
            showSectionHeaders: false,
          },
          sections: [
            {
              name: '',
              fieldGroup: [],
              hideExpression: '',
              hide: false,
              fields: [
                {
                  id: 'infoButtonForm',
                  key: 'infoButtonForm',
                  //type: 'instructions',
                  type: 'input',
                  templateOptions: {
                    //label: 'Import from S3 bucket',
                  },
                  data: {
                    displayField: 'name',
                    uniqueValueField: 'name',
                    optionIdField: 'name',
                  },
                  wrappers: ['info-button-wrapper'],
                },


              ],
            },
          ],
        },
        mode: FormModes.EDIT,
        formConfig: {},
        }
    }

    const dialogRef: MatDialogRef<any, any> = this.dialog.open(
      GenericDialogComponent,
        {
            panelClass: 'isc-form-modal',
            data: modalData,
            autoFocus: false
        }
    );
  }



}
