import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@intersystems/notification';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { FDN, Field, FormModes, IscFormModalData, Requirement, VALIDATORS } from '@intersystems/isc-form';
import { AbstractControl } from '@angular/forms';
import { BackupSchedules } from '../../icca-common/model/deployment-info';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GenericDialogComponent } from '../../icca-common/dialogs/generic/generic-dialog.component'; 
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IccaCommonService } from '../../icca-common/services/icca-common.service';
import { ExternalObjectStorageConfiguration, ExternalObjectStorageConfigurationList, ExternalStorageObject } from '../model/external-object-storage';


@Injectable({
  providedIn: 'root',
})
export class ExternalObjectStorageService {
  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private http: HttpClient,
    private iccaCommonService: IccaCommonService,
  ) {}


    openConfigInputModal(configModel, callback): void {
    
        const modalData: IscFormModalData = {
            modalTitle: 'External storage location',
            iscFormInputs: {
            Id: 's3InputForm',
            FDN: {
            name: '',
            description: '',
            validateOn: 'change',
            sectionLayout: { showSectionHeaders: true, showSectionCount:false },
            sections: [
                {
                fields: [
                    {
                    "key": "bucketArn",
                    "type": "input",
                    "hideExpression": "",
                    "hide": null,
                    "expressionProperties": null,
                    "className": "",
                    "data": {
                        //"displayField": data.status,
                        "content": '', //data.status,
                        "hint": ''
                    },
                    //note that the id won't get parent id prefixed because of the generic-info-wrapper
                    "id": "s3InputForm-bucketARN-input",
                    hintText: "Enter the ARN and region of the bucket, which you can get by selecting the bucket on the \
                    <a href='https://s3.console.aws.amazon.com/s3/buckets'  target='_blank'>AWS console's Buckets page</a>.\
                    <br><br>\
                    Click <b>Save</b>, select the bucket from the list, and click <b>List files</b> to display a bucket policy \
                    to be associated with the bucket on AWS.",
                    wrappers: ['generic-info-wrapper'],
                    "templateOptions": {
                        "label": "S3 bucket ARN",
                        "required": true,
                        "info-box-title":"Bucket ARN and region",
                    },
                    overrideValidatorMessage: {
                        [VALIDATORS.ISC_REQUIRED]: 'Required field',
                    },
                    },
                    {
                        "key": "region",
                        "type": "input",
                        "hideExpression": "",
                        "hide": null,
                        "expressionProperties": null,
                        "className": "",
                        "data": {
                        //"displayField": data.status,
                        "content": '', //data.status,
                        "hint": ''
                        },
                        //note that the id will get parent id prefixed so it will end up being
                        //s3InputForm-bucketRegion-input
                        "id": "bucketRegion",
                        "templateOptions": {
                          "label": "Region",
                          "required": true,
                        },
                        overrideValidatorMessage: {
                        [VALIDATORS.ISC_REQUIRED]: 'Required field',
                        },
                    },
        
                    ]
                }
            ]
            },
            formModel:  configModel,
            mode: FormModes.EDIT,
            formConfig: {},
            buttons: [
                {
                id: 'cancel',
                text: 'Cancel',
                buttonClass: 'tertiary',
                type: 'button',
                callback: (clickEvent: any, button: any, formModel: any, formOptions: any, form: any) => {
                    dialogRef.close();
                }
                },
                {
                id: 'save',
                text: 'Save',
                buttonClass: 'primary',
                disabledIfFormInvalid: true,
                disabledIfFormPristine: true,
                type: 'submit',
                callback: (clickEvent: any, button: any, formModel: any, formOptions: any, form: any) => {
                    callback(formModel, form, dialogRef);
                }
                },
            ]
            }
        }
    
        const dialogRef: MatDialogRef<any, any> = this.dialog.open(
        GenericDialogComponent,
            {
                panelClass: 'isc-form-modal',
                data: modalData,
                autoFocus: false
            }
        );
  
    }
  
  configurations$(deploymentType, deploymentId, region): Observable<ExternalObjectStorageConfigurationList> {
    const url = `${this.iccaCommonService.getAPIURL(deploymentType, region)}/external-object-storage/configurations/${deploymentId}`;
    return this.http.get<ExternalObjectStorageConfigurationList>(url);
  }



  addConfiguration(deploymentType, region:string, body:ExternalObjectStorageConfiguration): Observable<any> {
    const url = `${this.iccaCommonService.getAPIURL(deploymentType, region)}/external-object-storage/configurations`;
    return this.http.post(url, body);
  }

  deleteConfiguration(deploymentType, region:string, body: ExternalObjectStorageConfiguration): Observable<any> {
    const url = `${this.iccaCommonService.getAPIURL(deploymentType, region)}/external-object-storage/configurations`;
    return this.http.request('delete', url, { body: body})
  }


  listObjects$(deploymentType, region:string, body: ExternalObjectStorageConfiguration):Observable<any> {
    
    const url = `${this.iccaCommonService.getAPIURL(deploymentType, region)}/external-object-storage/objects`;
    return this.http.post(url, body);
  }

  
  transferObject$(deploymentType, region, body: ExternalStorageObject):Observable<any> {
    const url = `${this.iccaCommonService.getAPIURL(deploymentType, region)}/external-object-storage/transfer`;
    return this.http.post(url, body);
  }
}
