import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EPresetOptions, PaginatorConfig, TableConfig, TableService } from '@intersystems/table';
import { TableEnhancedIdService } from 'src/app/core/table-enhanced-id.service';
import { SharedUtils } from 'src/app/shared/shared-utils';
import { Sort } from "@angular/material/sort";
import { cloneDeep } from 'lodash';

import { FDN, Field, FormModes, IscFormModalData, VALIDATORS } from '@intersystems/isc-form';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl } from '@angular/forms';
import { ExternalObjectStorageConfiguration, ExternalObjectStorageConfigurationList, ExternalStorageObject, ExternalStorageObjectList } from 'src/app/deployments/icca-common/model/external-object-storage';
import { DeploymentsService } from 'src/app/deployments/deployments.service';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '@intersystems/notification';
import { SharedService } from 'src/app/shared/services/shared.service';
import { MatPaginator } from '@angular/material/paginator';
import { ConfirmationDialogComponent } from '@intersystems/confirmation-dialog';
import { ExternalObjectStorageService } from 'src/app/deployments/icca-common/services/external-object-storage.service';
import { FileHandlerService } from 'src/app/deployments/icca-common/services/file-handler.service';
import { FileListItem } from 'src/app/deployments/icca-common/model/file-list';



@Component({
  selector: 'app-icca-server-file-select-table',
  templateUrl: './server-file-select-table.component.html',
  styleUrls: ['./server-file-select-table.component.scss'],
})
export class IccaServerFileSelectTableComponent  {
  @Input() files!: any;
  @Input() loadInProgress:boolean = false;
  @Output() rowsChanged = new EventEmitter<void>();
  
  constructor(
    public tableService: TableService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private externalObjectStorageService: ExternalObjectStorageService,
    private fileHandlerService: FileHandlerService,
    private route: ActivatedRoute,
    ) {
  }

  

  field: Field;
  error = '';
  selectedConfigRowId: string = null;
  objectLoaded:string = ''; 

  serverFileSelectTableConfig: TableConfig = {
      "key": "serverFileSelectTable",
        cssTableClass: 'table-class',
        cssTRClass: 'table-row',
        cssTRClassFromRow: TableEnhancedIdService.setTableRowIdColumn('name'),
        onRowClick: (row: any) => {
          row['select'] = !row['select'];
          try {
            this.serverFileSelectTableConfig.columns
              .find(column => column.key === 'select')
              .cellDisplay.checkbox.onRowsChecked([row], row['select'], this.rowsChecked);
          } catch (e) {}
        },
        
        useSearch: true,
          "searchConfig": {
            "noEntriesFoundLabel": "No files found",
            "placeholderLabel": "Search",
            //"selectedRowHiddenLabel": "(hiddenRowCount: number) => string // TODO"
          },
        
          noDataMessage: 'No files uploaded.  Use Add and Manage Files page to upload files.',
        //stickyHeaderRow: false,
        sort: {
          sortFunction: (event: Sort, data: any) => {
            let sortedData = data.sort((a: any, b: any) => {
              const isAsc = event.direction === "asc";
              switch (event.active) {
                case "name":
                  return this.tableService.compareAlphaNumeric(a.name.toUpperCase(), b.name.toUpperCase(), isAsc);
                case "size":
                  return this.tableService.compareAlphaNumeric(+a.size, +b.size, isAsc);
    
                case "lastModifiedTime":
                  return this.tableService.compareAlphaNumeric(a.lastModifiedTime, b.lastModifiedTime, isAsc);
                default:
                  return 0
                  
              }
            });
            return sortedData;
          },
        },
        rowIdentifierProperty: 'name',
        columns: [
          {
            sortable: false,
            id: 'select',
            key: 'select',
            cellDisplay: {
              //model: 'select',
              preset: EPresetOptions.CHECKBOX,
              checkbox: {
                hideCheckAllBoxes: true,
                //rowIdentifierProperty: 'name',
                rowsChecked: [],
                rowCheckedStatusField: 'select',
                onRowsChecked: (rowsChecked, isCheck, allRowsChecked) => {
                  this.onFileSelect(rowsChecked, isCheck, allRowsChecked);
                }
               },
            },
          },
    
          {
            sortable: true,
            title: 'Name',
            id: 'name',
            key: 'name',
            cellDisplay: {
              getDisplay: (row: any, col: any) => {
                return row.name;
              },
            },
          },
          {
            //sortable: true,
            title: 'Size',
            id: 'size',
            key: 'size',
            cellDisplay: {
              getDisplay: (row: any, col: any) => {
                return this.fileHandlerService.formatFileSize(row.size) + ((row.type=="transfer") ? "/" + this.fileHandlerService.formatFileSize(row.totalSize) : '');
              },
            },
          },
          {
            //sortable: true,
            title: 'Date Modified',
            id: 'lastModifiedTime',
            key: 'lastModifiedTime',
            cellDisplay: {
              getDisplay: (row: any, col: any) => {
                if (!row?.lastModifiedTime) return '';
                //need to do this because safari doesn't support the - or . in the dateformat.
                var dateVal=row.lastModifiedTime;
                dateVal=dateVal.replace(/-/g, "/");
                //strip off decimal on the time if it exists.
                if (dateVal.indexOf('.')>0) {
                  dateVal=dateVal.substr(0, dateVal.indexOf('.')); 
                }
                dateVal=dateVal.toLocaleString();
                return dateVal;   //test.toLocaleString(); //row.lastModifiedTime.toLocaleString();
              },
            },
          },
    
        ],
      };
    
      // sync paginator
      paginatorConfig: PaginatorConfig = {
        pageSize: 10,
      };


  formModel: any = {
    nameSelected: '',
    deploymentId: '',
    rowsChecked: [],
    };
    
    //fileTableData: any[]; //=[];
    rowsChecked: any[] = [];
    //files: FileListItem[] = [];

  
  onFileSelect(rows,isCheck, allRowsChecked) {
    var row=rows[0];
    var select=row.select;
    
    this.files.forEach((item: any) => (item.select = false));
    row.select = select;
    if (select) {
      this.rowsChanged.emit(row.name);
    } else {
      this.rowsChanged.emit(undefined);
    }
}
 
  
}
