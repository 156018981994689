<fr-table [config]="sqlDiagConfig" [(data)]="sqlDiagData" [safeMode]="true" [paginatorConfig]="paginatorConfig"
  Id="sql-diag-logs-table">

  <div class="custom-table-header-space" table-header-space title="Hide">
    <button id="btn-sql-diag-refresh" mat-flat-button class="fr-primary button-padding" color="primary"
      (click)="getSQLDiagData()" [disabled]='loadInProgress'>
      <mat-icon>refresh</mat-icon>
      Refresh
    </button>
    <button id="btn-sql-diag-log-hide" mat-flat-button class="fr-primary button-padding" color="primary"
      (click)="hide()">
      Hide
    </button>
  </div>
</fr-table>