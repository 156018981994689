import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormButton, FDN, VALIDATORS } from '@intersystems/isc-form';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-invite-members-dialog',
  templateUrl: './invite-members-dialog.component.html',
})
export class InviteMembersDialogComponent {
  FDN: FDN = {
    name: '',
    description: '',
    validateOn: 'change',
    sectionLayout: { showSectionHeaders: false },
    sections: [
      {
        fields: [
          {
            id: 'email',
            key: 'email',
            type: 'input',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Email is a required field',
            },
            validators: {
              ['email']: {
                expression: (control: AbstractControl) => {
                  if (control.value) {
                    const emailPattern = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,40}$/;
                    return emailPattern.test(control.value);
                  }
                },
                message: () => 'Email must be in format name@host.com',
              },
              ['noDupes']: {
                expression: (control: AbstractControl) => {
                  if (control.value) {
                    return this.data.members.filter(item => item.email.toLowerCase() == control.value.toLowerCase()).length == 0;
                  }
                },
                message: () => 'This user is already invited',
              },
            },
            templateOptions: {
              type: 'email',
              required: true,
              label: 'Email',
            },
          },
          {
            id: 'role',
            key: 'role',
            type: 'select',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Role is a required field',
            },
            templateOptions: {
              label: 'Role',
              required: true,
              options: [
                {
                  name: 'Admin',
                  value: 'admin',
                },
                {
                  name: 'Write',
                  value: 'write',
                },
                {
                  name: 'Read',
                  value: 'read',
                },
              ],
            },
            validators: {
              ['isAdmin']: {
                expression: (control: AbstractControl) => !(control.value?.value == 'admin') || this.data.isAdmin,
                message: () => 'You can not invite another admin because you are not an admin',
              },
            },
            data: {
              displayField: 'name',
              uniqueValueField: 'value',
              optionIdField: 'name',
            },
          },
        ],
      },
    ],
  };

  buttons: FormButton[] = [    
    {
      id: 'btn-cancel',
      text: 'Cancel',
      buttonClass: 'secondary',
      type: 'reset',
      callback: () => this.dialogRef.close(false),
    },
    {
      id: 'btn-invite',
      text: 'Invite',
      buttonClass: 'primary',
      type: 'submit',
      callback: (clickEvent: any, button: any, formModel: any, _options, form) => {
        this.dialogRef.close(formModel);
      },
      disabledIfFormInvalid: true,
      disabledIfFormPristine: true,
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<InviteMembersDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
  ) {}
}
