<app-section header="Account Details">
  <fr-isc-form [FDN]="detailsFDN" Id="accountForm" [formModel]="detailsModel" [mode]="detailsMode$.value"
    [buttons]="detailsButtons">
  </fr-isc-form>
</app-section>
<app-section header="Account Password">
  <ng-container *ngIf="this.passwordMode$.value === 'view'">
    <fr-isc-form [FDN]="viewPasswordFDN" Id="viewPasswordForm" [formModel]="passwordModel"
      [mode]="this.passwordMode$.value" [buttons]="viewPasswordButtons">
    </fr-isc-form>
  </ng-container>

  <ng-container *ngIf="this.passwordMode$.value === 'edit'">
    <fr-isc-form [FDN]="editPasswordFDN" Id="editPasswordForm" [formModel]="passwordModel"
      [mode]="this.passwordMode$.value" [buttons]="editPasswordButtons">
    </fr-isc-form>
  </ng-container>
</app-section>

<app-section header="Multi-Factor Authentication">
  <qrcode *ngIf="!isTotpEnabled" [qrdata]="qrdata"></qrcode>
  <fr-isc-form [FDN]="mfaFDN" Id="mfaForm" [formModel]="mfaModel" mode="edit"
    [buttons]="isTotpEnabled?mfaEnabledButtons:mfaDisabledButtons">
  </fr-isc-form>

</app-section>