import { Component, OnDestroy, OnInit } from '@angular/core';
import { FDN, Field } from '@intersystems/isc-form';
import { NotificationService } from '@intersystems/notification';
import { SpinnerService } from '@intersystems/spinner';
import { FieldWrapper } from '@ngx-formly/core';
import { DeploymentObject } from 'api';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ExternalObjectStorageConfiguration, ExternalObjectStorageConfigurationList } from 'src/app/deployments/icca-common/model/external-object-storage';
import { ExternalObjectStorageService } from 'src/app/deployments/icca-common/services/external-object-storage.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-external-object-storage-wrapper',
  templateUrl: './isc-form-field-eos-wrapper.component.html',
  styleUrls: ['./isc-form-field-eos-wrapper.component.scss'],
})
export class IscFormFieldEOSWrapperComponent extends FieldWrapper implements OnInit, OnDestroy {
  field: Field;
  error = '';
  
  private sub = new Subscription();
  refreshToken$ = new BehaviorSubject<void>(undefined);
  public configs$: Observable<ExternalObjectStorageConfigurationList>;
  configs:any;
  public objects$: Observable<any>;
  objects:any;
  selectedBucket: ExternalObjectStorageConfiguration;
  showField: 'empty' | 'bucketPolicy' | 'tableData' | 'error' = 'empty';
  deployment:DeploymentObject;
  
  formModel: any = {
    bucketARN: '',
    bucketRegion: '',
    objectsToTransfer:[]
  };


  constructor(
    private notificationService: NotificationService,
    private externalObjectStorageService: ExternalObjectStorageService,
    private spinnerService: SpinnerService,
    private sharedService: SharedService,
    ) {
    super();
  }

  ngOnInit(): void {
    this.formModel = this.field.model;
    this.deployment= this.field.model.bucketForm;
    this.configs$ = this.refreshToken$.pipe(
      switchMap(() =>
        this.externalObjectStorageService.configurations$(this.deployment.deploymenttype,
          this.deployment.deploymentid,
          this.deployment.region
        ).pipe(),
      ),
    );
    this.sub.add(this.configs$.subscribe());
  }

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  addExternalObjectConfig (config: ExternalObjectStorageConfiguration): void {
    config.deploymentId=this.deployment.deploymentid;
      this.externalObjectStorageService.addConfiguration(this.deployment.deploymenttype, this.deployment.region, config).pipe(
        tap(response => {
          this.spinnerService.popStack();
          this.sharedService.showSuccess('External storage location added.');
          this.refreshToken$.next();
        }),
        catchError(response => {
          this.notificationService.showAlert(response.error.error, 7000);
          return of([]);
      })).subscribe();
    }




  
  deleteExternalObjectConfig (config: ExternalObjectStorageConfiguration): void {
    config.deploymentId=this.deployment.deploymentid;
    try {
      this.externalObjectStorageService.deleteConfiguration(this.deployment.deploymenttype, this.deployment.region, config).subscribe((response: any) => {
        this.spinnerService.popStack();
        this.sharedService.showSuccess(response.log);
        this.refreshToken$.next();
      });
    } catch (error) {
      const msg = 'Something went wrong. Please contact InterSystems Support.';
      this.notificationService.showAlert(msg, 7000);
    }
  }

  loadExternalObjects(selectedBucket) {
    this.selectedBucket=selectedBucket;
    this.selectedBucket.deploymentId=this.deployment.deploymentid;
    this.formModel.objectsToTransfer={};
    this.field.formControl.patchValue(this.formModel);

    this.objects$ = this.refreshToken$.pipe(
      switchMap(() =>
        this.externalObjectStorageService.listObjects$(this.deployment.deploymenttype,
          this.deployment.region,
          this.selectedBucket
        ).pipe(),
      ),
    );
    this.sub.add(this.objects$.subscribe());
  }

  rowsChanged(event):void {
    this.formModel.objectsToTransfer=event;
    this.field.formControl.patchValue(this.formModel);
  }
  
}
