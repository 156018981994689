import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableConfig } from '@intersystems/table';
import { SharedUtils } from '../../../shared/shared-utils';
import { TableEnhancedIdService } from 'src/app/core/table-enhanced-id.service';

@Component({
  selector: 'app-access-management',
  templateUrl: './access-management.component.html',
  styleUrls: ['./access-management.component.scss'],
})
export class AccessManagementComponent {
  @Input() members!: any;
  @Input() currentUser: any = {};
  @Input() tenantOwner = '';

  @Output() deleteUser = new EventEmitter<string>();
  @Output() inviteMember = new EventEmitter<void>();
  @Output() changeRole = new EventEmitter<any>();

  loading: false;

  membersTableConfig: TableConfig = {
    key: 'members-table',
    header: { title: 'Current Members' },
    cssNoDataMessageClass: 'no-data-message',
    noDataMessage: 'No members available',
    useSearch: true,
    searchConfig: {
      placeholderLabel: 'Search',
    },
    cssTRClassFromRow: TableEnhancedIdService.setTableRowIdColumn('table_id'),
    columns: [
      SharedUtils.getTableColumn('member', 'Member'),
      SharedUtils.getTableColumn('status', 'Status'),
      {
        key: 'role',
        id: 'role',
        title: 'Role',
        cellDisplay: {
          getDisplay: row => (row.username == this.tenantOwner ? 'owner' : row.role),
        },
      },
      {
        key: 'changeRole',
        id: 'changeRole',
        title: 'Change Role',
        cellDisplay: {
          preset: 'actionsDropDown',
          actionsDropDown: {
            hidden: row => row.username == this.tenantOwner || row.status != 'Active',
            actions: [
              {
                id: 'admin',
                text: 'Admin',
                callback: (_event, row) => this.changeRole.emit({ ...row, newRole: 'admin' }),
                hidden: row => row.role == 'admin',
                disabled: row => row.username == this.tenantOwner || row.status != 'Active',
              },
              {
                id: 'write',
                text: 'Write',
                callback: (_event, row) => this.changeRole.emit({ ...row, newRole: 'write' }),
                hidden: row => row.role == 'write',
                disabled: row => row.username == this.tenantOwner || row.status != 'Active',
              },
              {
                id: 'read',
                text: 'Read',
                callback: (_event, row) => this.changeRole.emit({ ...row, newRole: 'read' }),
                hidden: row => row.role == 'read',
                disabled: row => row.username == this.tenantOwner || row.status != 'Active',
              },
            ],
          },
        },
      },
      {
        key: 'action',
        id: 'action',
        title: 'Actions',
        cellDisplay: {
          preset: 'actionsIcons',
          actionsIcons: {
            delete: {
              id: 'delete',
              callback: (_event, row) => this.deleteUser.emit(row),
              hidden: row => row.username == this.tenantOwner,
            },
          },
        },
      },
    ],
  };
}
