<mat-drawer-container class="drawer-container" autosize=true hasBackdrop="false">
  <mat-drawer mode="side" opened (click)="closeHistory()">
    <app-schema-browser></app-schema-browser>
  </mat-drawer>

  <mat-drawer-content class="drawer-content" (click)="closeHistory()">
    <mat-card-title class="card-title">SQL Editor <app-info-button [htmlText]="infoObject.sqlEditor.htmlText"
        [title]="infoObject.sqlEditor.infoTitle"></app-info-button>
    </mat-card-title>
    <mat-card class="card" [ngClass]="{'loading-data': loadInProgress}">
      <mat-card-content>
        <div>
          <mat-spinner *ngIf="loadInProgress" [diameter]="30"></mat-spinner>
          <textarea [(ngModel)]="sqlQuery" id="sql-query" value={{sqlQuery}} (change)="saveQuery()"
            placeholder="SELECT * FROM ..." [disabled]="(loadInProgress)" rows="4" cols="50" autofocus tabindex="1">
          </textarea>
        </div>


        <mat-label>Response timeout (ms)
          <app-info-button [htmlText]="infoObject.responseTimeout.htmlText"
            [title]="infoObject.responseTimeout.infoTitle"></app-info-button>
        </mat-label>

        <fr-isc-form Id="queryForm" [FDN]="queryFDN" [(formModel)]="queryFormData" [mode]="'edit'" [formConfig]="">
        </fr-isc-form>

      </mat-card-content>

      <mat-card-actions>
        <button id="btn-clear-query" mat-stroked-button class="fr-tertiary button-padding" color="primary"
          (click)="clearQuery()" [disabled]="clearButtonDisabled()">
          Clear
        </button>
        <button id="btn-prev-query" mat-stroked-button class="fr-tertiary button-padding" color="primary"
          (click)="getQuery(-1)" [disabled]="(sqlQueryService.historyCursor<=0)||(loadInProgress)">
          <mat-icon>navigate_before</mat-icon>
          Previous
        </button>
        <button id="btn-execute-query" mat-flat-button class="fr-primary button-padding" color="primary"
          (click)="runQuery()" [disabled]="(trimQuery(sqlQuery)=='')||(loadInProgress)" tabindex="2">
          Execute
        </button>
        <button id="btn-next-query" mat-stroked-button class="fr-tertiary button-padding" color="primary"
          (click)="getQuery(1)"
          [disabled]="sqlQueryService.historyCursor>=(sqlQueryService.queryHistory.length-1)||(loadInProgress)">
          Next
          <mat-icon>navigate_next</mat-icon>
        </button>
        <button id="btn-query-history" mat-stroked-button class="fr-tertiary button-padding" color="primary"
          (click)="historyButtonClicked=true; updateHistory(); historyDrawer.open();"
          [disabled]="(sqlQueryService.queryHistory.length==0)||(loadInProgress)">
          <mat-icon>history</mat-icon>
          History
        </button>


      </mat-card-actions>

    </mat-card>


    <mat-card>
      <app-sql-query-table *reloadComponent="reloader"></app-sql-query-table>
    </mat-card>


  </mat-drawer-content>

  <mat-drawer #historyDrawer class="right-drawer" mode="over" position="end">
    <app-sql-query-history (queryRowSelected)="eventFromQueryHistory($event)"
      (hideQueryDrawer)="historyDrawer.toggle()">
    </app-sql-query-history>
  </mat-drawer>
</mat-drawer-container>