import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import { NotificationService } from '@intersystems/notification';
import { User, UsersService } from 'api';
import { Observable, forkJoin, from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.scss'],
})
export class AccountPageComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private router: Router,
    private notificationService: NotificationService,
    private sharedService: SharedService,
  ) {}
  user: User;
  totpSecret$: Observable<string>;
  isTotpEnabled$: Observable<boolean>;

  onDetailsEdit(formModel: any) {
    const userData: any = { first_name: formModel.firstName, last_name: formModel.lastName };
    this.usersService
      .patchUser(this.user.username, userData)
      .pipe(switchMap(() => this.authService.authorise('', '')))
      .subscribe(
        () => this.notificationService.showSuccess('Successfully updated', 7000),
        error => {
          let signUpFailMessage = error.message;
          if (signUpFailMessage.includes('#')) {
            signUpFailMessage = signUpFailMessage.split('#')[1].replace('.', '');
          }
          this.notificationService.showAlert(`Edit User Failed: ${signUpFailMessage}`, 7000);
        },
      );
  }

  onPasswordEdit(formModel: any) {
    this.authService.updatePassword(formModel.currentPassword, formModel.confirmPassword).subscribe(
      () => {
        this.notificationService.showSuccess('Successfully changed password. Please log in again.', 7000);
      },
      error => {
        let signUpFailMessage = error.message;
        if (signUpFailMessage.includes('#')) {
          signUpFailMessage = signUpFailMessage.split('#')[1].replace('.', '');
        }
        this.notificationService.showAlert(`Change Password Failed: ${signUpFailMessage}`, 7000);
      },
    );
  }

  onVerifyTotp(verificationCode: string) {
    from(Auth.currentUserPoolUser())
      .pipe(
        switchMap(user =>
          forkJoin([from(Auth.verifyTotpToken(user, verificationCode)), from(Auth.setPreferredMFA(user, 'TOTP'))]),
        ),
      )
      .subscribe(
        () => {
          this.notificationService.showSuccess('Successfully set up two factor authentication', 7000);
        },
        error => {
          this.notificationService.showAlert(`Two factor authentication setup Failed: ${error.message}`, 7000);
        },
      );
  }

  onDisableTotp() {
    from(Auth.currentUserPoolUser())
      .pipe(switchMap(user => from(Auth.setPreferredMFA(user, 'NOMFA'))))
      .subscribe(
        () => {
          this.notificationService.showSuccess('Successfully disabled two factor authentication', 7000);
          this.sharedService.reloadCurrentRoute();
        },
        error => {
          this.notificationService.showAlert(`Two factor authentication setup Failed: ${error.message}`, 7000);
        },
      );
  }
  ngOnInit(): void {
    this.user = this.authService.user;
    const cognitoUser$ = from(Auth.currentUserPoolUser() as Promise<CognitoUser>);
    this.totpSecret$ = cognitoUser$.pipe(switchMap(user => from(Auth.setupTOTP(user))));
    this.isTotpEnabled$ = cognitoUser$.pipe(
      switchMap(user => from(Auth.getPreferredMFA(user))),
      map(totpType => totpType == 'SOFTWARE_TOKEN_MFA'),
    );
  }
}
