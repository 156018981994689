import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { QueryHistory } from 'src/app/deployments/icca-common/model/sql-response'; 

import { TableConfig, PaginatorConfig } from '@intersystems/table';
import * as _ from 'lodash';
@Component({
  selector: 'app-sql-query-history',
  templateUrl: './sql-query-history.component.html',
  styleUrls: ['./sql-query-history.component.scss'],
})
export class SqlQueryHistoryComponent {
  @Output() queryRowSelected = new EventEmitter<string>();
  @Output() hideQueryDrawer = new EventEmitter<boolean>();

  tableConfig: TableConfig = {
    key: 'query-history-table',
    header: {
      title: 'Statement History',
      titleTooltip: {
        text: 'Statement History',
      },
    },
    cssTRClass: 'tr-hotspot',
    onRowClick: (row: any) => {
      this.queryRowSelected.emit(row.sqlStatement);
    },
    noDataMessage: 'No queries to display.',
    columns: [
      {
        title: 'SQL Statement',
        key: 'sqlStatement',
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row.sqlStatement;
          },
        },
      },
      {
        title: 'Last Executed',
        key: 'lastExecuted',
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row.lastExecuted.toLocaleString();
          },
        },
      },
      {
        title: 'Count',
        key: 'count',
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row.count;
          },
        },
      },
    ],
  };
  paginatorConfig: PaginatorConfig = {
    pageSize: 10,
  };

  queryHistory: QueryHistory[] = [];

  updateQueryHistory(queryHistory: QueryHistory[]): void {
    this.queryHistory = [];
    this.queryHistory = _.cloneDeep(queryHistory);
  }

  hideDrawer(): void {
    this.hideQueryDrawer.emit(true);
  }
}
