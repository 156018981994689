<ng-container *ngIf="!config.type">
  <ng-container *ngIf="data.inactiveSubscriptions.length > 0; else message">
    <div class="subsContainer" fxLayout="column" fxLayout.gt-xs="row">
      <div *ngFor="let item of data.inactiveSubscriptions">
        <mat-card class="subCard" *ngIf="showOffer(item.type)">
          <div class="indicator-panel"></div>
          <mat-card-header>
            <div mat-card-avatar [style]="'background-image: url(' + item.photoPath + '); background-size: cover;'">
            </div>
            <mat-card-title>{{ item.name }}</mat-card-title>
            <mat-card-subtitle *ngIf="item.isPreview">Preview</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            {{ item.description }}
          </mat-card-content>
          <mat-card-actions [align]="'end'">
            <button mat-stroked-button color="primary" [id]="'configure-btn-' + item.type"
              (click)="configure(item.type)">
              Configure
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </ng-container>
  <ng-template #message>
    <p>There is no available services for you now. Contact us for more info, please.</p>
  </ng-template>
</ng-container>

<ng-container *ngIf="config.type">
  <div *ngIf="config.type === 'fhiraas'">
    <fr-isc-form class="fhir-form" [Id]="'fhiraasCalculateForm'" [FDN]="fhiraasFDN" [formModel]="config" [mode]="'edit'"
      [buttons]="buttons">
    </fr-isc-form>
  </div>

  <div *ngIf="config.type === 'hmts'" fxLayout="row" fxLayoutGap="20px">
    <fr-isc-form [Id]="'hmtsCalculateForm'" [FDN]="hmtsFDN" [formModel]="config" [mode]="'edit'" [buttons]="buttons">
    </fr-isc-form>
  </div>

  <div *ngIf="config.type === 'hcc'" fxLayout="row" fxLayoutGap="20px">
    <fr-isc-form [Id]="'hccCalculateForm'" [FDN]="hccFDN" [formModel]="config" [mode]="'edit'" [buttons]="buttons">
    </fr-isc-form>
  </div>

  <div *ngIf="config.type === 'sqlaas'">
    <fr-isc-form class="sqlaas-form" [Id]="'sqlaasCalculateForm'" [FDN]="sqlaasFDN" [formModel]="config" [mode]="'edit'"
      [buttons]="buttons">
    </fr-isc-form>
  </div>

</ng-container>