import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../core/auth.service';
import { Deployment } from '../core/interfaces/deployment';
import { User } from '../core/interfaces/user';
import versionJson from '../../assets/.info.json';

@Injectable({
  providedIn: 'root',
})
export class AdministrationService {
  constructor(private authService: AuthService, private http: HttpClient) {}

  users$(): Observable<User[]> {
    const url = environment.API_URL + '/users';
    return this.http.get<User[]>(url);
  }
  patchUser$(username: string, body: any): Observable<User> {
    const url = environment.API_URL + '/users/' + username;
    return this.http.patch<User>(url, body);
  }

  downloadAdminKey$(stackName): Observable<any> {
    const secretName = stackName + '-key';
    const url = environment.API_URL + '/secret?secretname=' + secretName + '&keytype=mspkey';
    return this.http.get(url);
  }

  updateDeployment$(tenantid: string, deploymentid: string): Observable<any> {
    const url = environment.API_URL + '/tenants/' + tenantid + '/deployments/' + deploymentid;
    return this.http.patch<User>(url, {});
  }

  deleteDeployment$(tenantid: string, deploymentid: string): Observable<any> {
    const url = environment.API_URL + '/tenants/' + tenantid + '/deployments/' + deploymentid;
    return this.http.delete(url);
  }
  toggleDeployment$(tenantid: string, deploymentid: string, action: string): Observable<any> {
    const url = environment.API_URL + '/tenants/' + tenantid + '/deployments/' + deploymentid + '/power';
    return this.http.patch(url, { action });
  }
  hydrateDeployment$(tenantid: string, deploymentid: string, snapshotid: string): Observable<any> {
    const url = environment.API_URL + '/tenants/' + tenantid + '/deployments/' + deploymentid + '/iris';
    return this.http.patch(url, { snapshotid });
  }
  getPasswordforDeployment$(tenantid: string, deploymentid: string): Observable<any> {
    // /tenants/{tenantid}/deployments/{deploymentid}/password
    const url = environment.API_URL + '/tenants/' + tenantid + '/deployments/' + deploymentid + '/password';
    return this.http.get(url);
  }
  getCodes$(): Observable<any> {
    const url = environment.API_URL + '/codes';
    return this.http.get(url);
  }
  addCodes$(quantity: number): Observable<any> {
    const url = environment.API_URL + '/codes';
    return this.http.post(url, { quantity });
  }
  getVersionData$() {
    return of(versionJson);
  }
  getLaunchResources$(): Observable<any> {
    const url = environment.API_URL + '/admin/resources';
    return this.http.get(url);
  }
}
