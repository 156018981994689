import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { SQLQueryService } from '../sql-query/sql-query.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent, ConfirmationDialogConfig } from '@intersystems/confirmation-dialog';
import { ImportResponse, ImportTableConfig } from '../../icca-common/model/sql-response';
import { NotificationService } from '@intersystems/notification';
import { ImportService } from '../import/import.service'; 
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, repeatWhen, startWith, take, takeWhile, tap } from 'rxjs/operators';
import { DeploymentObject } from 'api';
import { DeploymentsService } from 'src/app/deployments/deployments.service';
import { IRISAuthService } from '../../icca-common/components/iris-login/iris-auth.service'; 
import { IMLTrainingInfoComponent } from './iml-training-info/iml-training-info.component';
import { MatDrawer } from '@angular/material/sidenav';
import { IMLService } from './iml.service';
import { IccaDeploymentObject } from '../../icca-common/model/deployment-info';
import { IrisConnection } from '../../icca-common/model/connection';
import { SharedService } from 'src/app/shared/services/shared.service';


@Component({
  selector: 'app-iml-tools',
  templateUrl: './iml-tools.component.html',
  styleUrls: ['./iml-tools.component.scss'],
})
export class IMLToolsComponent implements OnInit, OnDestroy {
  @ViewChild(IMLTrainingInfoComponent) private trainingInfoComponent:IMLTrainingInfoComponent;
  @ViewChild('trainDrawer') trainDrawer: MatDrawer;
 
  constructor(
    private sqlQueryService: SQLQueryService,
    private notificationSvc: NotificationService,
    private route: ActivatedRoute,
    private deploymentsService: DeploymentsService,
    private irisAuthService: IRISAuthService,
    private router: Router,
    public imlService: IMLService,
    public sharedService: SharedService,
  ) {}

  deployment: IccaDeploymentObject | undefined;
  deployment$: Observable<DeploymentObject>;  
  private sub = new Subscription();
  private deploymentSub = new Subscription();
  tableData = [];
  modelData = [];
  trainedModelData=[];
  colList = [];
  
  sqlTrainModel='';
  hideSqlTrainModel=true;
  sqlCreateModel='';
  hideSqlCreateModel=true;
  sqlValidateModel='';
  hideSqlValidateModel=true;
  

  sqlEditorLabel='Edit SQL';

  sqlPredictPlaceholder='e.g.\n Select \n top 100 \n predict(readmissionModel)*1 prediction,\n\
  readmitted, \n\
  probability(readmissionModel) probability_readmit, \n\
  * \n\
  from \n\
  Data.Diabetes10K';

  createIsOpen:boolean=false;
  trainIsOpen:boolean=false;
  predictIsOpen:boolean=false;

  loadInProgress = false;
  
  //modelName = '';
  //trainedName='';
  //sqlPredict=''; 
  
  /*"SELECT \n \
  PREDICT(readmissionModel) as prediction, \n \
  readmitted, \n \
  * \n\
  FROM \n \
  Data.Diabetes10K";
    */
  infoObject = {
    title: {
      infoTitle: 'IntegratedML',
      htmlText: "Use this page to execute the four basic tasks in the IntegratedML \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_Basics'  target='_blank'>workflow</a>: \
      <b>create</b> a model, <b>train</b> it as a predictive model, <b>validate</b> the trained model, \
      and make <b>predictions</b>. In each step, IntegratedML creates a SQL statement that executes the \
      task as you have defined it. If you wish, you can edit the SQL before executing the task. \
      <br><br> \
      Before creating a model, \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_Basics#GIML_Basics_Create_Prep'  target='_blank'>prepare the data</a> \
      on which it will be based, then use the <b>Import Files</b> page to import DML to create the needed \
      schema and table and load CSV data into the table."
    },
    create: {
      infoTitle: 'IntegratedML create',
      htmlText: "Expand this panel to \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_Basics#GIML_Basics_Create'  target='_blank'>create a model</a>\
      , which defines the elements used by IntegratedML to train a predictive model; it contains metadata \
      about the input fields (features), the predicted field (label), and the data types of these fields."
    },
    train: {
      infoTitle: 'IntegratedML train',
      htmlText: "Expand this panel to \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_Basics#GIML_Basics_Train'  target='_blank'>train a predictive model</a> \
      using the configured <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_Configuration_Providers'  target='_blank'>provider</a>\
      (H2O), which uses a structured process to compare the performance of different machine learning model \
      types (linear regression, random forest, and so on) with the data and return the appropriate trained predictive model."
    },
    validate: {
      infoTitle: 'IntegratedML validate',
      htmlText: "Expand this panel to \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_Basics#GIML_Basics_Validate'  target='_blank'>validate a trained predictive model</a> \
      against data like the data it was trained on. Validation returns \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_VALIDATEMODEL#GIML_VALIDATEMODEL_Metrics'  target='_blank'>simple metrics</a> \
      for both regression (data range) models and classification (category) models."
    },
    predict: {
      infoTitle: 'IntegratedML predict',
      htmlText: "Expand this panel to \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_Basics#GIML_Basics_Predict'  target='_blank'>predict</a> \
      the output field (label) value for each row in a set of data containing the input fields (features) the \
      model was trained on. Classification models can also call on the provider to return the probability that \
      the predicted value is the correct result for the model."
    },
    createModel: {
      infoTitle: 'Model name',
      htmlText: "Enter a name for the model, which must be a valid \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GSQL_identifiers'  target='_blank'>identifier</a>, \
      subject to the same \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=RSQL_createtable#RSQL_createtable_tname'  target='_blank'>naming restrictions</a> \
      as a table name. The name and details are recorded in the \
      ML_MODELS view within the \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_Maintain#GIML_Using_Maintain_View'  target='_blank'>INFORMATION_SCHEMA class</a>, \
      which you can query on the <b>SQL Query Tools</b> page."
    },
    createTable: {
      infoTitle: 'Table to train model on',
      htmlText: "The <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GSQL_basics_tables'  target='_blank'>table</a> \
      on which the model will be \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_Basics#GIML_Basics_Train'  target='_blank'>trained</a>. \
      All existing tables in the deployment are listed in the dropdown; if you need to add one, go to the <b>Import Files</b> \
      page to import DML to create the needed schema and table and load CSV data into the table.<br><br>\
      Bear in mind that to validate the model you will need more data of the same schema as your training data, including the \
      input fields (also known as features) and the predicted field (also known as the label)."
    },
    createField: {
      infoTitle: 'Field to predict',
      htmlText: "The field the model will predict, also known as the label. All fields in the selected table are listed in the dropdown. When you \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_Basics#GIML_Basics_Train'  target='_blank'>train the model</a>, the \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_Configuration_Providers'  target='_blank'>provider</a> \
      uses the training data to identify patterns that map the input fields (features) to the desired predicted field."
    },
    createButtons: {
      infoTitle: 'Create model',
      htmlText: "Select <b>Create model</b> to execute the \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_Basics' target='_blank'>CREATE MODEL statement</a> \
      and move to the Train panel. \
      You can also select <b>Edit SQL</b> to modify the statement before executing it with <b>Create model</b>."
    },
    trainModel: {
      infoTitle:'Model to train',
      htmlText: "All models defined in the deployment are shown with their prediction fields (labels) in the dropdown. \
      To predict a different field or otherwise modify the model, open the <b>Create</b> panel and define a new model based \
      on the same table."
    },
    trainName: {
      infoTitle: 'Trained model name',
      htmlText: "Optionally enter a name for the trained model, which must be a valid \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GSQL_identifiers'  target='_blank'>identifier</a>, \
      subject to the same \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=RSQL_createtable#RSQL_createtable_tname'  target='_blank'>naming restrictions</a> \
      as a table name; if you do not, IntegratedML names it for you. The name is displayed on the Trained Models panel and recorded in \
      the ML_TRAINED_MODELS view within the \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_Maintain#GIML_Using_Maintain_View'  target='_blank'>INFORMATION_SCHEMA class</a>, \
      which you can query on the <b>SQL Query Tools</b> page."
    },
    trainButtons: {
      infoTitle: 'Train model',
      htmlText:"Click <b>Train model</b> to execute the \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_TRAINMODEL'  target='_blank'>TRAIN MODEL statement</a> \
      or <b>Edit SQL</b> to modify the statement before executing it. The Training Runs panel is displayed, showing the status of the training run. \
      You can list current or completed training runs or all trained predictive models at any time using the appropriate button.\
      <br><br>\
      Within the \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_Maintain#GIML_Using_Maintain_View'  target='_blank'>INFORMATION_SCHEMA class</a>, \
      training runs are recorded in the ML_TRAINING_RUNS view and trained models in the ML_TRAINED_MODELS \
      view; you can query these views on the <b>SQL Query Tools</b> page.<br><br>\
      You can repeat a completed training run (for example, if the data has changed) by using the same \
      trained model name, which overwrites the previous trained model, or a different one, \
      which does not.  If you omit the trained model name, IntegratedML generates a new on, preserving the earlier version."
    },
    validateModel: {
      infoTitle: 'Trained model to validate',
      htmlText:"All available trained predictive models are shown in the dropdown. To train another, \
      return to the <b>Train</b> panel."
    },
    validateTable: {
      infoTitle: 'Table to validate model from',
      htmlText:"The <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GSQL_basics_table'  target='_blank'>table</a> \
      against which the trained model will be validated. This must contain additional data of the same \
      schema as your training data, including the input fields (features) and the predicted field (label).<br><br>\
      All existing tables in the deployment are listed in the dropdown; if you need to add one, go to the \
      <b>Import Files</b> page to import DML to create the needed schema and table and load CSV data into the table."
    },
    validateRun: {
      infoTitle: 'Validation run name',
      htmlText:"Optionally enter a name for the validation run; if you do not, IntegratedML names it for you. The name is \
      displayed on the Validation Runs panel and is recorded in the ML_VALIDATION_RUNS view within the \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_Maintain#GIML_Using_Maintain_View'  target='_blank'>INFORMATION_SCHEMA class</a>, \
      which you can query on the <b>SQL Query Tools</b> page."
    },
    validateButtons: {
      infoTitle: 'Validate model',
      htmlText:"Click <b>Validate model</b> to execute the \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_VALIDATEMODEL'  target='_blank'>VALIDATE MODEL statement</a> \
      or <b>Edit SQL</b> to modify the statement before executing it. The Validation Runs panel is displayed, showing the status of the validation run. \
      You can list current or completed validation runs or examine the \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_VALIDATEMODEL#GIML_VALIDATEMODEL_Metrics'  target='_blank'>validation metrics</a> \
      produced by those runs at any time using the appropriate button.<br><br>\
      Within the \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_Maintain#GIML_Using_Maintain_View'  target='_blank'>INFORMATION_SCHEMA class</a>, \
      validation runs are recorded in the ML_VALIDATION_RUNS view, and metrics for each run in the \
      ML_VALIDATION_METRICS view; you can query these views on the <b>SQL Query Tools</b> page.\
      <br><br>\
      You can repeat a completed validation run (for example, if the data has changed) using the same \
      validation run name, which overwrites the previous run, or by entering a different name, \
      which does not. If you omit the run name, IntegratedML generates a new one, preserving the earlier version."
    },
    predictModel: {
      infoTitle:'Trained model to predict',
      htmlText: "All available trained predictive models are shown in the dropdown. To train another, \
      return to the <b>Train</b> panel."
    },
    predictTable: {
      infoTitle:'Table containing input fields',
      htmlText: "The <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GSQL_basics_table'  target='_blank'>table</a> \
      from which predictions will be made. This must contain additional data of the same schema as your \
      training data, including the input fields (features). If the predicted field (label) is included it \
      is ignored, as are any additional fields.<br><br>\
      All existing tables in the deployment are listed in the dropdown; if you need to add one, go to the \
      <b>Import Files</b> page to import DML to create the needed schema and table and load CSV data into the table."
    },
    predictTop: {
      infoTitle:'Number of rows to predict, from top',
      htmlText: "Number of rows for which to predict the value of the prediction field, starting from the first row."
    },
    predictGenerate: {
      infoTitle:'Generate SQL',
      htmlText: "Generate and display the SQL statement for prediction based on your entries. The \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_Basics#GIML_Basics_Predict_Predict' target='_blank'>PREDICT</a> function \
      returns the estimated (for regression models) or most likely (for classification models) value for the \
      prediction field (label) in each row. For classification models only, the \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_Basics#GIML_Basics_Predict_Probability' target='_blank'>PROBABILITY</a> \
      function returns for each row the probability that the specified value is the predicted label value, \
      allowing you to evaluate the relative strength of predictions of that value."
    },
    predictEdit: {
      infoTitle:'Execute on SQL Query Tools',
      htmlText: "Click to go the <b>SQL Query Tools</b> page to optionally edit the details of the \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_PREDICT'  target='_blank'>PREDICT function</a> \
      (such as use of the WITH clause to predict based on user-provided column values rather than a table) or the \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_PROBABILITY'  target='_blank'>PROBABILITY function</a> \
      before executing it and examining the results.",
    },
    //<a href=''  target='_blank'></a>
  };

  

  ngOnInit(): void {
    this.getDeployment();
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.sub.unsubscribe();
 }
  getDeployment() {
    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    
    this.deploymentSub.add(this.deploymentsService.deployment$(deploymentId).subscribe(deployment => {
      if (deployment) {
        this.deployment=deployment;
        const connectionInfo = this.irisAuthService.getIRISConnectionInfo(deploymentId, deployment.deploymenttype);
        if ((connectionInfo.username=='')||(connectionInfo.password=='')) {
          this.router.navigate(['/deployments', deploymentId, 'irislogin']);
          //this.router.navigate(['/']);
          return;
        }
        this.loadTables();
        this.loadModels();
        this.loadTrainedModels();
        this.deploymentSub.unsubscribe();
      }
    },
       //lambda will return 404 if deployment is deleted
       err =>{
        if (err?.status==404) {
          this.sharedService.showAlert('Deployment not found');
          this.router.navigate(['/deployments']);
  
        }
        else {
          this.sharedService.showAlert('Error getting deployment info: ' + err.error.error);
        }     
      }
      ));
  }
  
  loadTables() {
    if (!this.deployment) return;
    //load tables into dropdown
    const schemaQuery =
      "SELECT TABLE_SCHEMA,TABLE_NAME FROM INFORMATION_SCHEMA.TABLES WHERE NOT TABLE_SCHEMA %STARTSWITH '%' AND NOT TABLE_SCHEMA %STARTSWITH 'ENS' AND TABLE_SCHEMA <> 'INFORMATION_SCHEMA'";

    this.tableData = [];
    this.sub.add(this.sqlQueryService.executeSQLStatement(this.deployment, schemaQuery).subscribe(sqlResponse => {
      this.tableData = sqlResponse['resultSet'].data;
    }));
    return;
  }

  loadModels() {
    if (!this.deployment) return;
    
    //load tables into dropdown
    const schemaQuery =
      "SELECT MODEL_NAME, PREDICTING_COLUMN_NAME, PREDICTING_COLUMN_TYPE FROM INFORMATION_SCHEMA.ML_MODELS";

    this.modelData = [];
    
    this.sub.add(this.sqlQueryService.executeSQLStatement(this.deployment, schemaQuery).subscribe(sqlResponse => {
      this.modelData = sqlResponse['resultSet'].data;
    }));
    return;
  }

  tableSelected($event) {
    //event will fire twice when selection is changed.
    //The _selected property will be true for the item that was selected.
    if ($event.source._selected) {
      //load fields into an input box
      const selected = $event.source.value.toString();
      this.imlService.imlFormData.create.selectedTable = selected;
      this.loadColumns();
    }
  }
  colSelected($event) {
    //event will fire twice when selection is changed.
    //The _selected property will be true for the item that was selected.
    if ($event.source._selected) {
      //load fields into an input box
      const selected = $event.source.value.toString();
      //this.imlService.imlFormData.create.selectedCol = selected.substring(0,selected.indexOf(' '));
      this.imlService.imlFormData.create.selectedCol = selected //selected.substring(0,selected.indexOf(' '));
      //this.loadColumns();
    }
  }
  modelSelected($event) {
    //event will fire twice when selection is changed.
    //The _selected property will be true for the item that was selected.
    if ($event.source._selected) {
      //load fields into an input box
      const selected = $event.source.value.toString();
      this.imlService.imlFormData.train.selectedModel = selected;
      //this.loadColumns();
    }
  }
  validateModelSelected ($event) {
    if ($event.source._selected) {
      const selected = $event.source.value.toString().split(",");
      this.imlService.imlFormData.validate.trainedModelToValidate=selected[0];
      this.imlService.imlFormData.validate.selectedValidateModel = selected[1];
    }
  }
  tableForValidationSelected($event) {
    if ($event.source._selected) {
      const selected = $event.source.value.toString();
      this.imlService.imlFormData.validate.selectedTableForValidation = selected;
      //this.loadColumns();
    }
    
  }

  predictTableSelected($event) {
    //event will fire twice when selection is changed.
    //The _selected property will be true for the item that was selected.
    if ($event.source._selected) {
      //load fields into an input box
      const selected = $event.source.value.toString();
      this.imlService.imlFormData.predict.predictSelectedTable = selected;
      //if using a classification model, find the first row of classification to use in PREDICT
      this.imlService.imlFormData.predict.predictClassification="";
      switch (this.imlService.imlFormData.predict.predictFieldType) {
        case "VARCHAR":
        case "BIT":
        case "INTEGER":
          const sql =
          `SELECT TOP(1) ${this.imlService.imlFormData.predict.predictField} FROM ${this.imlService.imlFormData.predict.predictSelectedTable} WHERE ${this.imlService.imlFormData.predict.predictField} <> ''`;
    
          this.sub.add(this.sqlQueryService.executeSQLStatement(this.deployment, sql).subscribe(sqlResponse => {
            this.imlService.imlFormData.predict.predictClassification = sqlResponse['resultSet'].data[0];
            this.loadInProgress=false;
          }));
          break;
        default:
      }
      
    }
  }
  predictModelSelected($event) {

    if ($event.source._selected) {
      const selected = $event.source.value.toString().split(",");
      this.imlService.imlFormData.predict.predictTrainedModelSelected = selected[0];
      this.imlService.imlFormData.predict.predictModelSelected = selected[1];
      
      //find the model in this.modelData and set the predictField and predictFieldType
      this.modelData.forEach(model => {
        if (model[0]===this.imlService.imlFormData.predict.predictModelSelected) {
          this.imlService.imlFormData.predict.predictField=model[1];
          this.imlService.imlFormData.predict.predictFieldType=model[2];
          return; 
        }
      });
    }
  }

  generateSQL() {
    //check trained model
    //look for a model_type to determine if its regression or classification
    //if model_type not specified, look at data type of field used, 
    //if it's string, integer or binary, then use classification, if not use regression

    //console.log(this.imlService.imlFormData.predict);
    
    let modelType:"regression" | "classification";
    switch (this.imlService.imlFormData.predict.predictFieldType) {
      case "VARCHAR":
      case "BIT":
      case "INTEGER":
        modelType="classification";
        break;
      default:
        modelType="regression";
    }

    if (this.imlService.imlFormData.predict.predictClassification=='') {
      this.imlService.imlFormData.predict.predictClassification='REPLACE WITH CLASSIFICATION'
    }

    this.imlService.imlFormData.predict.sqlPredict=`SELECT \n\
      TOP(${this.imlService.imlFormData.predict.predictTop})
      PREDICT(${this.imlService.imlFormData.predict.predictModelSelected} use ${this.imlService.imlFormData.predict.predictTrainedModelSelected}) as prediction, \n\
      ${this.imlService.imlFormData.predict.predictField}, \n\
      ${modelType=='classification' ? `PROBABILITY(${this.imlService.imlFormData.predict.predictModelSelected} use ${this.imlService.imlFormData.predict.predictTrainedModelSelected} ${this.imlService.imlFormData.predict.predictFieldType=='BIT' ? '' : `for '${this.imlService.imlFormData.predict.predictClassification}'`}) as probability_${this.imlService.imlFormData.predict.predictField},` : ''}
      * \n\
    FROM \n\
      ${this.imlService.imlFormData.predict.predictSelectedTable}`;
  }

  loadColumns() {
    if (!this.deployment) return;

    const tableName = this.imlService.imlFormData.create.selectedTable;
    const split = tableName.split('.');
    this.colList = [];

    const schemaQuery = `SELECT COLUMN_NAME, DATA_TYPE, CHARACTER_MAXIMUM_LENGTH, IS_NULLABLE, IS_IDENTITY  FROM INFORMATION_SCHEMA.COLUMNS WHERE TABLE_SCHEMA='${split[0]}' AND TABLE_NAME='${split[1]}' ORDER BY COLUMN_NAME`;
    
        
    this.sub.add(this.sqlQueryService.executeSQLStatement(this.deployment, schemaQuery).subscribe(sqlResponse => {
      sqlResponse['resultSet'].data.forEach(col => {
        let display: string;
        display = col[0]; //name

        if (col[4] == 'YES') {
          display = display + ' IDENTITY ';
        } else {
          display = display + ` ${col[1].toUpperCase()}`; //name and datatype
          //max length
          if (col[2] != 'null') {
            display = display + `(${col[2]})`;
          }
          //is nullable
          if (col[3] == 'NO') {
            //display=display + ' NOT NULL ';
          }
        }
        this.colList.push({
          name: col[0],
          dataType: col[1],
          maxLength: col[2],
          isNullable: col[3],
          isIdentity: col[4],
          display: display,
        });
      });
    }));
    return;
  }


  loadTrainedModels() {
    if (!this.deployment) return;
    this.loadInProgress = true;
    
    const sql =
      `SELECT TRAINED_MODEL_NAME,MODEL_NAME FROM INFORMATION_SCHEMA.ML_TRAINED_MODELS`;


    
    this.sub.add(this.sqlQueryService.executeSQLStatement(this.deployment, sql).subscribe(sqlResponse => {
      this.trainedModelData = sqlResponse['resultSet'].data;
      this.loadInProgress=false;
    }));
    
  }
  trainedModelOpenChanged(isOpen:boolean){
    if (isOpen) {
      this.loadTrainedModels();
    }
  }

  trainedModelCompareMethod(o1: any, o2: any): boolean {
    return JSON.stringify(o1) === JSON.stringify(o2);
  }
  

  createModel() {
    
    //load tables into dropdown
    if (!this.deployment) return;
    var sql:string;
    if (this.hideSqlCreateModel) {
      //use sql from guide
      sql = `CREATE MODEL ${this.imlService.imlFormData.create.modelName} PREDICTING (${this.imlService.getDisplay(this.imlService.imlFormData.create.selectedCol)}) FROM ${this.imlService.imlFormData.create.selectedTable}` ;
        } else {
      //use sql from text box
      sql=this.sqlCreateModel;
    }

    this.sub.add(this.sqlQueryService.executeSQLStatement(this.deployment, sql).subscribe(sqlResponse => {
      if (typeof sqlResponse != 'undefined') {
        if (typeof sqlResponse['resultSet'] != 'undefined') {
          //sqlResultSetData = sqlResponse['resultSet'].data;
        }
    
        if (typeof sqlResponse['error'] != 'undefined') {
          var error:string = '';
          try {
            error = sqlResponse['error'].split('[%msg: <')[1].split('>]')[0];
          } catch (e) {}
          
          if (error=='') {
            try {
              error=sqlResponse['error'].split('java.sql.SQLException:')[1];
            } catch (e) {}
          }
  
          if (error=='') {
            error=sqlResponse['error'];
          }
          
          error='Error creating model: ' + error;
          this.notificationSvc.showAlert(error, 7000);
        }
        if (typeof sqlResponse['errorMessage'] != 'undefined') {
          this.notificationSvc.showAlert(sqlResponse['errorMessage'], 7000);
        }
        if (typeof sqlResponse['rowsUpdated'] != 'undefined') {
          this.notificationSvc.showInfo('Model created', 7000);
        }
      }

      this.loadModels(); //reload models
    }));
  }
  
  createdModelsClick() {
    this.trainingInfoComponent.type="createdModels";
    this.trainingInfoComponent.getCreatedModels();
    this.trainDrawer.open();
  }

  trainModel() {

    
    const connectionInfo = this.irisAuthService.getIRISConnectionInfo(this.deployment.deploymentid, this.deployment.deploymenttype);
    const trainedName=this.imlService.imlFormData.train.trainedName;
    
    var sql:string;

    if (this.hideSqlTrainModel) {
      //use sql from guide
      sql = `TRAIN MODEL ${this.imlService.getDisplay(this.imlService.imlFormData.train.selectedModel)} ${(trainedName!='') ? "As " + trainedName : ''}` ;
    } else {
      //use sql from text box
      sql=this.sqlTrainModel;
    }
    
    this.sub.add(this.sqlQueryService.executeSQLStatement(this.deployment, `CALL IRISCloud.SQLUtils_ExecuteSQL('${sql}','1', '%H2O')`)
    .subscribe(sqlResponse =>{
      if (typeof sqlResponse != 'undefined') {
        if (typeof sqlResponse['resultSet'] != 'undefined') {
          //sqlResultSetData = sqlResponse['resultSet'].data;
        }
    
        if (typeof sqlResponse['error'] != 'undefined') {
          var error:string = '';
          try {
            error = sqlResponse['error'].split('[%msg: <')[1].split('>]')[0];
          } catch (e) {}
          
          if (error=='') {
            try {
              error=sqlResponse['error'].split('java.sql.SQLException:')[1];
            } catch (e) {}
          }
  
          if (error=='') {
            error=sqlResponse['error'];
          }
          
          error='Error training model: ' + error;
          this.notificationSvc.showAlert(error, 7000);
        }
        if (typeof sqlResponse['errorMessage'] != 'undefined') {
          this.notificationSvc.showAlert(sqlResponse['errorMessage'], 7000);
        }
        if (typeof sqlResponse['rowsUpdated'] != 'undefined') {
          this.notificationSvc.showInfo('Training started', 7000);
        }

      }  
        this.trainingInfoComponent.getTrainingRuns();
        this.trainingInfoComponent.type="trainingRuns"
        this.trainDrawer.open();
    }));
  }

  trainingRunsClick() {
    this.trainingInfoComponent.type="trainingRuns";
    this.trainingInfoComponent.getTrainingRuns();
    this.trainDrawer.open();
  }

  trainedModelsClick() {
    this.trainingInfoComponent.type="trainedModels";
    this.trainingInfoComponent.getTrainedModels();
    this.trainDrawer.open();
  }
  
  validationRunsClick() {
    this.trainingInfoComponent.type="validationRuns";
    this.trainingInfoComponent.getValidationRuns();
    this.trainDrawer.open();
  }
  validationMetricsClick() {
    this.trainingInfoComponent.type="validationMetrics";
    this.trainingInfoComponent.getValidationMetrics();
    this.trainDrawer.open();
  }



  validateModel() {
    
    const connectionInfo = this.irisAuthService.getIRISConnectionInfo(this.deployment.deploymentid, this.deployment.deploymenttype);
      
    //load tables into dropdown
    const validationName = this.imlService.imlFormData.validate.validationName;
    var sql:string;
    if (this.hideSqlValidateModel) {
      //use sql from guide
      sql=`VALIDATE MODEL ${this.imlService.imlFormData.validate.selectedValidateModel}  ${(validationName!='') ? "As " + validationName : ''} FROM ${this.imlService.imlFormData.validate.selectedTableForValidation}` ;
    } else {
      //use sql from text box
      sql=this.sqlValidateModel;
    }
    
      this.sub.add(this.sqlQueryService.executeSQLStatement(this.deployment, `CALL IRISCloud.SQLUtils_ExecuteSQL('${sql}','1', '%H2O')`)
        .subscribe(sqlResponse =>{
          if (typeof sqlResponse != 'undefined') {
            if (typeof sqlResponse['resultSet'] != 'undefined') {
              //sqlResultSetData = sqlResponse['resultSet'].data;
            }
        
            if (typeof sqlResponse['error'] != 'undefined') {
              var error:string = '';
              try {
                error = sqlResponse['error'].split('[%msg: <')[1].split('>]')[0];
              } catch (e) {}
              
              if (error=='') {
                try {
                  error=sqlResponse['error'].split('java.sql.SQLException:')[1];
                } catch (e) {}
              }
      
              if (error=='') {
                error=sqlResponse['error'];
              }
              
              error='Error validating model: ' + error;
              this.notificationSvc.showAlert(error, 7000);
            }
            if (typeof sqlResponse['errorMessage'] != 'undefined') {
              this.notificationSvc.showAlert(sqlResponse['errorMessage'], 7000);
            }
            if (typeof sqlResponse['rowsUpdated'] != 'undefined') {
              this.notificationSvc.showInfo('Validation started', 7000);
            }
          }
          this.trainingInfoComponent.getValidationRuns();
          this.trainingInfoComponent.getValidationMetrics();
          this.trainingInfoComponent.type="validationRuns";
          this.trainDrawer.open();
    }));
  }

  editInSQLEditor(type,sql) {

    switch (type) {
      case 'create':
        this.hideSqlCreateModel=!this.hideSqlCreateModel;
        this.sqlCreateModel=sql;
        return;
      case 'train':
        this.hideSqlTrainModel=!this.hideSqlTrainModel;
        this.sqlTrainModel=sql;
        return;
      case 'validate':
        this.hideSqlValidateModel=!this.hideSqlValidateModel;
        this.sqlValidateModel=sql;
        return;
      case 'predict':
        const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
        this.sqlQueryService.setSQLQuery(sql);
        this.router.navigate(['/deployments', deploymentId, 'sqlquery']);
        return;
    }

     
  }
  


  selectionChange($event): void {
    //console.log($event.selectedIndex);
  }

  responseHandler(response, successNotification) {
    if (typeof response != 'undefined') {
      if (typeof response['resultSet'] != 'undefined') {
        //sqlResultSetData = response['resultSet'].data;
      }
  
      if (typeof response['error'] != 'undefined') {
        var error:string = '';
        try {
          error = response['error'].split('[%msg: <')[1].split('>]')[0];
        } catch (e) {}
        
        if (error=='') {
          try {
            error=response['error'].split('java.sql.SQLException:')[1];
          } catch (e) {}
        }

        if (error=='') {
          error=response['error'];
        }
        
        error='Error creating model: ' + error;
        this.notificationSvc.showAlert(error, 7000);
      }
      if (typeof response['errorMessage'] != 'undefined') {
        this.notificationSvc.showAlert(response['errorMessage'], 7000);
      }
      if (typeof response['rowsUpdated'] != 'undefined') {
        this.notificationSvc.showInfo(successNotification, 7000);
      }
    }
  }
}
