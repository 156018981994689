import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { DeploymentObject } from 'api';

import { DeploymentsService } from '../deployments.service';
import { DeploymentValidGuard } from './deployment-valid.guard';

@Injectable({
  providedIn: 'root',
})
export class DeploymentDrGuard extends DeploymentValidGuard implements CanActivate {
  constructor(deploymentsService: DeploymentsService, router: Router) {
    super(deploymentsService, router);
  }

  canActivateDeployment(deployment: DeploymentObject): boolean {
    return deployment.features?.['dr'];
  }
}
