import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormButton, FDN } from '@intersystems/isc-form';

@Component({
  selector: 'app-deployment-service-level-dialog',
  templateUrl: './deployment-service-level-dialog.component.html',
})
export class DeploymentServiceLevelDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DeploymentServiceLevelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  FDN: FDN = {
    name: 'Service Level Deployment',
    description: `You can specify a Service Level here. Please note that changing the Service Level could modify the SLA (Service Level Agreement) and could incur additional cost.`,
    validateOn: 'change',
    sectionLayout: { showSectionHeaders: false },
    sections: [
      {
        fields: [
          {
            id: 'deploymentName',
            key: 'deploymentName',
            type: 'input',
            viewOnly: true,
            templateOptions: {
              label: 'Deployment name',
            },
            data: {},
          },
          {
            id: 'service_level',
            key: 'service_level',
            type: 'select',
            viewOnly: false,
            data: {
              displayField: 'name',
              uniqueValueField: 'value',
              optionIdField: 'name',
            },
            wrappers: ['hint-info'],
            templateOptions: {
              label: 'Service Level',
              required: true,
              placeholder: 'No Service Level',
              options: [
                {
                  name: 'Development',
                  value: 'Development',
                },
                {
                  name: 'Test',
                  value: 'Test',
                },
                {
                  name: 'Live',
                  value: 'Live',
                },
                {
                  name: 'Default',
                  value: 'Default',
                },
              ],
            },
          },
          {
            id: 'service_level_urgency',
            key: 'service_level_urgency',
            type: 'select',
            viewOnly: false,
            data: {
              displayField: 'name',
              uniqueValueField: 'value',
              optionIdField: 'name',
            },
            wrappers: ['hint-info'],
            templateOptions: {
              label: 'Service Level Urgency',
              required: true,
              placeholder: '1',
              options: [
                {
                  name: '1',
                  value: '1',
                },
                {
                  name: '2',
                  value: '2',
                },
                {
                  name: '3',
                  value: '3',
                },
                {
                  name: '4',
                  value: '4',
                },
                {
                  name: '5',
                  value: '5',
                },
              ],
            },
          },
        ],
      },
    ],
  };

  buttons: FormButton[] = [
    {
      text: 'Cancel',
      buttonClass: 'secondary',
      id: 'cancel',
      type: 'submit',
      callback: () => this.dialogRef.close(false),
    },
    {
      text: 'Submit',
      buttonClass: 'primary',
      id: 'submit',
      type: 'submit',
      callback: (_event, _button, formModel) => this.dialogRef.close(formModel),
    },
  ];

  ngOnInit(): void {
    // remove extra spaces
    this.data.deploymentName = this.data.deploymentName.replace(/\s+/g, ' ').trim();
  }
}
