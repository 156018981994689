import { DeploymentObject } from "api";
import { DeploymentInfo } from "../../icca-common/model/deployment-info";

export enum SDSDeployerStatus { 
    PROVISIONING = "PROVISIONING",
    DEPROVISIONING = "DEPROVISIONING",
    ACTIVATING = "ACTIVATING",
    DEACTIVATING = "DEACTIVATING",
    PENDING = "PENDING",
    RUNNING = "RUNNING",
    DEPLOYING = "DEPLOYING",
    STOPPING = "STOPPING",
    STOPPED = "STOPPED",
    FAILED = "FAILED", 
    UNKNOWN = "UNKNOWN",
    COMPLETE = "FINISHED SUCCESSFULLY", 
} 

export enum EKSClusterStatus { 
    CREATING = "CREATING",
    ACTIVE = "ACTIVE", 
    DELETING = "DELETING",
    FAILED = "FAILED",
    UPDATING = "UPDATING",
    PENDING = "PENDING",
} 

export enum EKSPODStatus { 
    CREATING = "Creating",
    PENDING = "Pending",
    RUNNING = "Running",
    SUCCEEDED = "Succeeded",
    FAILED = "Failed",
    DELETED = "Deleted",
    UNKNOWN = "Unknown",
} 

export interface SdsDeploymentObject extends DeploymentObject {
    created_at?:string;
}

export interface SdsDeploymentInfo extends DeploymentInfo  {
    deployerStatus: SDSDeployerStatus
    eksClusterStatus: EKSClusterStatus
    controlPlaneFrontendStatus: "Deleted" | "Failed" | "Creating" | "Pending" | "Running" | "Succeeded" | "Unknown";
    controlPlaneBackendStatus: "Deleted" | "Failed" | "Creating" | "Pending" | "Running" | "Succeeded" | "Unknown";
    controlPlaneDomain: string; 
}
