import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormButton, FDN } from '@intersystems/isc-form';

@Component({
  selector: 'app-overview-dialog-stop',
  styleUrls: ['./feedback.component.scss'],
  templateUrl: './feedback.component.html',
})
export class AppFeedbackComponent {
  constructor(public dialogRef: MatDialogRef<AppFeedbackComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
  feedback = '';

  getListCode() {
    return (
      'curl -i -H "x-api-key:<YOUR-API-KEY>" -k -X GET --url ' +
      this.data?.deployment?.resource_list.awsfhirendpoint +
      '/Patient'
    );
  }

  getCreateCode() {
    return (
      'curl -i -X POST "' +
      this.data?.deployment?.resource_list.awsfhirendpoint +
      '/Patient" -H "x-api-key:<YOUR-API-KEY>" -H "accept: application/fhir+json" -H "Content-Type: application/fhir+json" -i -d "{ \\"name\\": [ { \\"use\\": \\"official\\", \\"family\\": \\"Johnson\\", \\"given\\": [ \\"Darcy\\" ] } ], \\"gender\\": \\"female\\", \\"birthDate\\": \\"1970-02-02\\", \\"resourceType\\": \\"Patient\\" }"'
    );
  }
}
