import { Component } from '@angular/core';
import { Field } from '@intersystems/isc-form';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-isc-form-field-hint-wrapper',
  templateUrl: './isc-form-field-hint-wrapper.component.html',
  styleUrls: ['./isc-form-field-hint-wrapper.component.scss'],
})
export class IscFormFieldHintWrapperComponent extends FieldWrapper {
  field: Field;
}
