import { Component, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { TenantsService, User } from 'api';
import { Subject, Observable, BehaviorSubject, of } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SERVICES_DATA } from 'src/app/deployments/common/create/servicesData';
import { DeploymentOptions } from 'src/app/deployments/data/deployment-options';
import { DeploymentsService } from 'src/app/deployments/deployments.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { Cloudprovider } from '../interfaces/deployment';

type Breadcrumb = { caption?: string; url?: Array<UrlSegment[]> };
const regions = DeploymentOptions.regions;

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private deploymentsService: DeploymentsService,
    private sharedService: SharedService,
    private authService: AuthService,
    private tenantsService: TenantsService,
  ) {}
  @Input() performanceSupportDrawerOpened = false;
  @Output() performanceSupportDrawerToggle = new EventEmitter<any>();

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private routeChange$: Observable<Event>;
  breadcrumbs: Breadcrumb[] = [];
  servicesData = JSON.parse(JSON.stringify(SERVICES_DATA)); // deep clone original data
  private serviceStatus$: Observable<any>;
  refreshToken$ = new BehaviorSubject<void>(undefined);
  tenantOffers: any;
  providers = Cloudprovider;
  sqlaasEnabled = false;
  user$: Observable<User>;
  user: User;
  currentTenantId$: Observable<string>;
  userTenants: any;

  ngOnInit(): void {
    const navigationEndEvents$ = this.router.events.pipe(filter(event => event instanceof NavigationEnd));
    this.routeChange$ = navigationEndEvents$.pipe(
      tap(() => this.updateBreadcrumbs()),
      takeUntil(this._unsubscribeAll),
    );
    this.routeChange$.subscribe();
    this.enablePerformanceSuppport();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  updateBreadcrumbs() {
    this.breadcrumbs = [];
    let url = [];
    let currentPart = this.activatedRoute;
    while (currentPart) {
      if (currentPart.snapshot?.data?.caption && currentPart.snapshot?.url[0] !== undefined) {
        if (currentPart.snapshot.paramMap.get('deploymentId') != null) {
          this.breadcrumbs.push({
            caption: this.deploymentsService.getDeploymentName(currentPart.snapshot.paramMap.get('deploymentId')),
            url: url.concat([currentPart.snapshot.paramMap.get('deploymentId'), 'overview']),
          });
        }
        url = url.concat(currentPart.snapshot.url.map(segment => segment.path));
        this.breadcrumbs.push({ caption: currentPart.snapshot.data.caption, url: [...url] });
      }
      currentPart = currentPart.firstChild;
    }
  }

  onRefresh() {
    this.sharedService.reloadCurrentRoute();
  }

  //determine what offers are enabled to display performance support
  enablePerformanceSuppport() {
    // We have to push user info into Top Bar again because it
    // does not get the initial value from its service correctly
    this.user$ = this.authService.user$.pipe(
      tap(user => {
        if (user) {
          this.user = user;
          this.userTenants = user.tenants;
          this.currentTenantId$ = this.authService.currentTenantId$;
          this.checkServicesStatus();
          this.serviceStatus$.subscribe();
        }
      }),
      tap(user => {
        this.enableAllOffersForAdminsAndSe(user);
      }),
      takeUntil(this._unsubscribeAll),
    );
    this.user$.subscribe();
  }
  // Find and enable offer by name
  enableOffer(offerName) {
    this.servicesData.forEach(section =>
      section.offers
        .filter(offer => offer.name == offerName || offerName == 'all')
        .forEach(offer => (offer.enabled = true)),
    );
    if (offerName == 'sqlaas' || offerName == 'all') this.sqlaasEnabled = true;
  }

  enableAllOffersForAdminsAndSe(user) {
    if (user) {
      if (user.is_admin || user.is_se) {
        this.enableOffer('all');
      }
    }
  }

  checkServicesStatus(): void {
    // is offer status in progress
    this.serviceStatus$ = this.refreshToken$.pipe(
      switchMap(() => this.currentTenantId$),
      switchMap(tenantId => {
        return this.tenantsService.getTenant(tenantId);
      }),
      map(data => {
        this.tenantOffers = data.offers;
        Object.keys(data.offers).map(offerId => {
          const offerData = data.offers[offerId] as { enabled: boolean; providers: any };
          if (offerData.enabled) this.enableOffer(offerId);
        });
      }),
    );
  }
}
