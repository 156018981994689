import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-open-api',
  templateUrl: './open-api.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class OpenApiComponent {
  @Input() apiDescriptionUrl: string;
  @Input() basePath: string;
  @Input() layout = 'sidebar';
}
