<ng-container *ngIf="members">
  <fr-table class='mt-20px' [config]="membersTableConfig" [data]="members" Id="members-table">
    <div class="table-sub-header" above-table-body-space>
      <app-loading-spinner *ngIf="!members"></app-loading-spinner>
    </div>

    <div class="custom-table-header-space" table-header-space>
      <button id="btn-invite-members" mat-flat-button class="fr-primary" color="primary" (click)='inviteMember.emit()'>
        Invite Members
      </button>
    </div>
  </fr-table>
</ng-container>
