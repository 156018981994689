import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { DeploymentObject } from 'api';
import { Observable } from 'rxjs';

import { DeploymentsService } from '../deployments.service';
import { IccaCommonService } from '../icca-common/services/icca-common.service';

@Injectable({
  providedIn: 'root',
})
export class IccaRunningGuard implements CanActivate {
  constructor(
    private deploymentsService: DeploymentsService,
    private iccaCommonService: IccaCommonService,
    ) {}


  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.deploymentsService.deployments) return true;
    if (route.paramMap.get('deploymentId') == '') return false;
    const currentDeployment = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      route.paramMap.get('deploymentId'),
    ) as DeploymentObject;
    if (!currentDeployment) return false;
    if (this.deploymentsService.getOfferArchitecture(currentDeployment.deploymenttype) != 'icca') return false;
    if (!this.iccaCommonService.deploymentInfo) return false;
    return (this.iccaCommonService.deploymentInfo.info.deploymentState.state.toUpperCase()=='RUNNING' && this.iccaCommonService.deploymentInfo.info.irisState.instanceStatus.toUpperCase()=='RUNNING');
  }

}