import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-overview-dialog-stop',
  styleUrls: ['./request.component.scss'],
  templateUrl: './request.component.html',
})
export class RequestComponent {
  constructor(public dialogRef: MatDialogRef<RequestComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
  modalBody = `I would like to request ${this.data.request} to ${this.data.name}.`;
}
