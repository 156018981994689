import { Component } from '@angular/core';
import { AdminService } from 'admin-api';
import { tap } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AdministrationService } from '../administration.service';

@Component({
  selector: 'app-performanceprofiles-page',
  templateUrl: './performanceprofiles-page.component.html',
  styleUrls: ['./performanceprofiles-page.component.scss'],
})
export class PerformanceProfilesPageComponent {
  constructor(
    private administrationService: AdministrationService,
    private adminService: AdminService,
    private sharedService: SharedService,
  ) {}
  performanceProfiles$ = this.adminService.getPerformanceProfiles();
}
