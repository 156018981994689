/**
 * Portal Admin
 * Thehose APIs are only available for portal administrators 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: portal-dev@intersystems.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The object schema for CreateNotificationObject
 */
export interface CreateNotificationObject { 
    firstName?: string;
    lastName?: string;
    email?: string;
    username?: string;
    appContext?: string;
    errorDescription?: string;
    url?: string;
}

