/**
 * Portal Admin
 * Thehose APIs are only available for portal administrators 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: portal-dev@intersystems.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The input schema to the backup API endpoint that supports both backup and restore functionality
 */
export interface BackupCreateSnapshot { 
    deploymentId?: string;
    eventType?: BackupCreateSnapshot.EventTypeEnum;
    recoveryPointArn?: string;
}
export namespace BackupCreateSnapshot {
    export type EventTypeEnum = 'backup' | 'restore';
    export const EventTypeEnum = {
        Backup: 'backup' as EventTypeEnum,
        Restore: 'restore' as EventTypeEnum
    };
}


