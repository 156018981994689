import { Component } from '@angular/core';
import { Field } from '@intersystems/isc-form';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-isc-form-select-wrapper',
  templateUrl: './isc-form-select-wrapper.component.html',
  styleUrls: ['./isc-form-select-wrapper.component.scss'],
})
export class IscFormSelectWrapperComponent extends FieldWrapper {
  field: Field;

  getViewOnlyMode(field: Field) {
    if (typeof field.viewOnly === 'boolean') {
      return field.viewOnly;
    } else if (typeof field.viewOnly === 'function') {
      return field.viewOnly(field.formControl, field);
    } else {
      return false;
    }
  }
}
