import { Directive, Input, OnChanges,TemplateRef,ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Directive({
  selector: '[reloadComponent]'
})
export class ReloadComponentDirective implements OnChanges {

  @Input('reloadComponent')
  private reload: BehaviorSubject<boolean>;

  private subscription: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) { }

  ngOnChanges() {
    this.subscription = this.reload.subscribe(show => {
      this.viewContainer.clear();

      if (show) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}