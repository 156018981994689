import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'sqlaas-documentation-redirect',
  template: ``,
})
export class SqlaasDocumentationRedirect {
  constructor(private _location: Location) {
    window.open('https://docs.intersystems.com/services/csp/docbook/DocBook.UI.Page.cls?KEY=PAGE_iriscloudsql');
    this._location.back();
  }
}
