import { Component, OnDestroy } from '@angular/core';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AdministrationService } from '../administration.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { Deployment } from 'src/app/core/interfaces/deployment';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { ConfirmationDialogComponent, ConfirmationDialogConfig } from '@intersystems/confirmation-dialog';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/core/interfaces/user';
import { DeploymentService } from 'admin-api';
import { SpinnerService } from '@intersystems/spinner';
import { DeploymentDeleteDialogComponent } from 'src/app/deployments/common/list/dialogs/deployment-delete/deployment-delete-dialog.component';

@Component({
  selector: 'app-deployments-page',
  templateUrl: './deployments-page.component.html',
  styleUrls: ['./deployments-page.component.scss'],
})
export class DeploymentsPageComponent implements OnDestroy {
  refreshToken$ = new BehaviorSubject<void>(undefined);

  viewDeployment$ = new BehaviorSubject<Deployment>(null);
  updateDeploymentVersion$ = new BehaviorSubject<Deployment>(null);

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private deploymentFilter = 'active';

  deployments$ = this.refreshToken$.pipe(
    switchMap(() => this.deploymentService.getDeployments(this.deploymentFilter)),
    map((data: any) => {
      return data ? data.sort((one, two) => Date.parse(two.updated_at) - Date.parse(one.updated_at)) : undefined;
    }),
    takeUntil(this._unsubscribeAll),
  );

  users$ = this.refreshToken$.pipe(
    switchMap(() => this.administrationService.users$()),
    map(users => users.reduce((obj, cur) => ((obj[cur.username] = cur), obj), {})),
    tap(users => console.log(users)),
    takeUntil(this._unsubscribeAll),
  );

  deploymentsWithStatuses$ = combineLatest([this.deployments$, this.users$]).pipe(
    map(([deployments, users]) => {
      const deploymentsWithStatuses: Deployment[] = [];
      const usersArray: User[] = Object.values(users);
      const tenantNames = {};
      usersArray.forEach(user => {
        Object.keys(user.tenants).forEach(tenantId => (tenantNames[tenantId] = user.tenants[tenantId].name));
      });

      deployments.forEach((deployment: Deployment & { subscriptionStatus: string; subscription: any }) => {
        if (deployment.subscription != null) {
          // Subscribed
          if (deployment.subscription.terms != null) {
            // New subscription with terms
            if (deployment.subscription.terms.end_date) {
              // New trial subscription with end date
              deployment.subscriptionStatus = 'trial';
            } else {
              // New commercial subscription with no end date
              deployment.subscriptionStatus = 'commercial';
            }
          } else {
            // Legacy (commercial) subscription
            deployment.subscriptionStatus = 'legacy';
          }

          // Getting the subscription status
          if (deployment.subscription.status == 'unsubscribe-success') {
            // Inactive/expired subscription
            deployment.subscriptionStatus += 'Inactive';
          } else {
            deployment.subscriptionStatus += 'Active';
          }
        }
        deployment.tenant_name = tenantNames[deployment.tenantid];
        deploymentsWithStatuses.push(deployment);
      });
      return deploymentsWithStatuses;
    }),
  );

  constructor(
    private administrationService: AdministrationService,
    private sharedService: SharedService,
    private deploymentService: DeploymentService,
    private clipboard: Clipboard,
    private dialog: MatDialog,
    private spinnerService: SpinnerService,
  ) {}

  downloadKey(deployment: Deployment) {
    this.administrationService
      .downloadAdminKey$(deployment.stackname)
      .pipe(
        tap((key: BlobPart) => {
          const fileURL = window.URL.createObjectURL(new Blob([key]));
          const fileLink = document.createElement('a');

          const keyNameWithExt = deployment.deploymentname.replace(/\s+/g, '') + '.pem';
          fileLink.href = fileURL;
          fileLink.setAttribute('download', keyNameWithExt);
          document.body.appendChild(fileLink);

          fileLink.click();
        }),
        catchError(() => of(this.sharedService.showAlert('Error downloading key.'))),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe();
  }

  refreshResources(deployment: Deployment) {
    this.administrationService
      .updateDeployment$(deployment.tenantid, deployment.deploymentid)
      .pipe(
        tap(() => this.sharedService.showSuccess('Refreshed resources for ' + deployment.deploymentname)),
        tap(() => this.refreshToken$.next()),
        catchError(() =>
          of(this.sharedService.showAlert('Error refreshing resources for ' + deployment.deploymentname)),
        ),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe();
  }

  viewDeployment(deployment: Deployment) {
    this.viewDeployment$.next(deployment);
  }

  toggleDeployment(deployment: Deployment, action: string) {
    this.administrationService
      .toggleDeployment$(deployment.tenantid, deployment.deploymentid, action)
      .pipe(
        tap(() => this.sharedService.showSuccess('Scheduled ' + action + ' of ' + deployment.deploymentname)),
        tap(() => this.refreshToken$.next()),
        catchError(() =>
          of(this.sharedService.showAlert('Error during ' + action + ' of ' + deployment.deploymentname)),
        ),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe();
  }

  deleteDeployment(deployment: Deployment) {
    this.dialog
      .open(DeploymentDeleteDialogComponent, {
        data: { deploymentName: deployment.deploymentname },
      })
      .afterClosed()
      .pipe(
        filter(dialogResult => dialogResult),
        switchMap(() => this.administrationService.deleteDeployment$(deployment.tenantid, deployment.deploymentid)),
        tap(() => this.sharedService.showSuccess('Scheduled ' + deployment.deploymentname + ' for deletion')),
        tap(() => this.refreshToken$.next()),
        catchError(() => of(this.sharedService.showAlert('Error deleting ' + deployment.deploymentname))),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe();
  }

  hydrateDeployment(deployment: Deployment) {
    const dialogConfig: ConfirmationDialogConfig = {
      title: 'Hydrate Deployment',
      primary: 'Are you sure you want to hydrate ' + deployment.deploymentname + '?',
      hideCloseButton: true,
      buttons: {
        primary: {
          text: 'Confirm',
        },
        secondary: {
          text: 'Cancel',
        },
      },
    };
    this.dialog
      .open(ConfirmationDialogComponent, {
        panelClass: 'fr-layout-wrapper-mat-dialog-panel',
        data: dialogConfig,
      })
      .afterClosed()
      .pipe(
        filter(response => response?.button == 'primary'),
        switchMap(() =>
          this.administrationService.hydrateDeployment$(
            deployment.tenantid,
            deployment.deploymentid,
            deployment.resource_list['snapupversion'],
          ),
        ),
        tap(() => this.sharedService.showSuccess('Scheduled ' + deployment.deploymentname + ' for hydration')),
        tap(() => this.refreshToken$.next()),
        catchError(() => of(this.sharedService.showAlert('Error hydrating ' + deployment.deploymentname))),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe();
  }

  getPasswordforDeployment(deployment: Deployment) {
    const dialogConfig: ConfirmationDialogConfig = {
      title: 'Deployment Password',
      primary: 'Put the password for ' + deployment.deploymentname + ' on your clipboard?',
      hideCloseButton: true,
      buttons: {
        primary: {
          text: 'Copy',
        },
        secondary: {
          text: 'Cancel',
        },
      },
    };
    this.dialog
      .open(ConfirmationDialogComponent, {
        panelClass: 'fr-layout-wrapper-mat-dialog-panel',
        data: dialogConfig,
      })
      // think I may have to subscribe to the response here below, but how
      .afterClosed()
      .pipe(
        filter(response => response?.button == 'primary'),
        tap(() => this.spinnerService.pushStack('Getting Deployment Password')),
        switchMap(() =>
          this.administrationService.getPasswordforDeployment$(deployment.tenantid, deployment.deploymentid),
        ),
        tap(() => this.refreshToken$.next()),
        tap(response => this.clipboard.copy(response.password)),
        tap(() => {
          while (this.spinnerService.hasPendingRequest()) this.spinnerService.popStack();
        }),
        tap(() => this.sharedService.showSuccess('Copied password for ' + deployment.deploymentname)),
        catchError(() => {
          while (this.spinnerService.hasPendingRequest()) this.spinnerService.popStack();
          return of(this.sharedService.showAlert('Error getting password for ' + deployment.deploymentname));
        }),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe();
  }
  copyClick(text) {
    console.log(text);
    this.clipboard.copy(text);
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  loadDeleted() {
    this.deploymentFilter = 'all';
    this.refreshToken$.next();
  }
}
