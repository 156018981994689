import { Component, OnInit } from '@angular/core';
import { Field } from '@intersystems/isc-form';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-isc-form-field-generic-info-wrapper',
  templateUrl: './isc-form-field-generic-info-wrapper.component.html',
  styleUrls: ['./isc-form-field-generic-info-wrapper.component.scss'],
})
export class IscFormFieldGenericInfoWrapperComponent extends FieldWrapper implements OnInit{
  field: Field;
  formState: any;
  model: any;

  fieldLabel:string;
  inputType:string;
  inputPlaceholder:string;
  infoBoxTitle:string;

  constructor () {
    super();
  }

  ngOnInit() {
    this.infoBoxTitle = this.field.templateOptions['info-box-title'] ? this.field.templateOptions['info-box-title'] : this.field.templateOptions.label;
    switch (this.field.type) {
      case 'radio':
      case 'select':
        //preserve label so we can hide original
        this.fieldLabel=this.field.templateOptions.label;
        this.field.templateOptions.label='';
        this.field.templateOptions.required=true; 
        break;
      case 'number':
        this.inputType='number';
        break;
      case 'input':
        this.inputType='text';
        this.inputPlaceholder=this.field.templateOptions.placeholder;
        break;
      default:
        break;
    }
  }

  fieldChange(event,field) {
   
    this.field.formControl.markAsDirty();
    this.field.formControl.setValue(this.model[field]);
    this.field.formControl.updateValueAndValidity();
  }

  getErrorMessage() {
    if (this.field.formControl.hasError('unique')) {
      return this.field.formControl.errors.unique.message();
    }

    if (this.field.formControl.hasError('isc-required')) {
      return 'Required field';
    }

    if (this.field.formControl.hasError('nameCheck')) {
      return this.field.formControl.errors.nameCheck.message();
    }
    
    return ''; 
  }
}