import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderModule } from '@intersystems/header';
import { NotificationModule } from '@intersystems/notification';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { FooterComponent } from './footer/footer.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { AppRoutingModule } from '../app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';
import { SpinnerModule } from '@intersystems/spinner';
import { AppFeedbackComponent } from './dialogs/feedback.component';
import { TableService } from '@intersystems/table';
import { TableEnhancedIdService } from './table-enhanced-id.service';
import { WebpackTranslateLoader } from '../shared/webpack-translate-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TenantSwitcherComponent } from './tenant-switcher/tenant-switcher.component';
import { PerformanceSupportComponent } from './performance-support/performance-support.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  imports: [
    CommonModule,

    NgProgressModule.withConfig({
      spinner: false,
      color: '#008F8A',
      thick: true,
      debounceTime: 300,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    NgProgressHttpModule,
    HeaderModule,
    NotificationModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    SpinnerModule,
    MatToolbarModule,
    MatExpansionModule,
  ],
  declarations: [FooterComponent, BreadcrumbsComponent, AppFeedbackComponent, TenantSwitcherComponent, PerformanceSupportComponent],
  providers: [{ provide: TableService, useClass: TableEnhancedIdService }],
  exports: [
    HeaderModule,
    NotificationModule,
    BrowserAnimationsModule,
    NgProgressModule,
    FooterComponent,
    BreadcrumbsComponent,
    AppRoutingModule,
    BrowserModule,
    SharedModule,
    SpinnerModule,
    AppFeedbackComponent,
    TenantSwitcherComponent,
    PerformanceSupportComponent,
    TranslateModule,
  ],
})
export class CoreModule {}
