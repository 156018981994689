/**
 * Portal Admin
 * Thehose APIs are only available for portal administrators 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: portal-dev@intersystems.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Situation report
 */
export interface Sitrep { 
    sitrepid?: string;
    created_at?: string;
    rto_at?: string;
    ack_at?: string;
    rto_duration?: string;
    ack_duration?: string;
    rto_iscsupport?: string;
    ack_iscsupport?: string;
    source?: string;
    status?: Sitrep.StatusEnum;
    body?: string;
    subject?: string;
    deploymentid?: string;
    tenantid?: string;
    gitlab_issue_url?: string;
}
export namespace Sitrep {
    export type StatusEnum = 'open' | 'in progress' | 'closed';
    export const StatusEnum = {
        Open: 'open' as StatusEnum,
        InProgress: 'in progress' as StatusEnum,
        Closed: 'closed' as StatusEnum
    };
}


