import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'icca-troubleshooting-redirect',
  template: ``,
})
export class IccaTroubleshootingRedirect {
  constructor(private _location: Location) {
    window.open('https://docs.intersystems.com/services/csp/docbook/DocBook.UI.Page.cls?KEY=ACTS');
    this._location.back();
  }
}
