import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { DeploymentObject } from 'api';
import { Observable } from 'rxjs';
import { DeploymentsService } from '../deployments.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkConnectivityGuard implements CanActivate {
  constructor(private deploymentsService: DeploymentsService) {}

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.deploymentsService.deployments) return true;
    if (route.paramMap.get('deploymentId') == '') return false;
    const currentDeployment = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      route.paramMap.get('deploymentId'),
    ) as DeploymentObject;
    if (!currentDeployment) return false;
    if (
      currentDeployment.deploymenttype != 'spc' &&
      currentDeployment.deploymenttype != 'ics' &&
      currentDeployment.deploymenttype != 'fhiraas' &&
      currentDeployment.deploymenttype != 'business360' &&
      currentDeployment.deploymenttype != 'hcc'
    )
      return false;
    if (currentDeployment.status != 'COMPLETE') return false;
    return true;
  }
}
