import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginatorConfig, TableConfig, TableService } from '@intersystems/table';
import { TableEnhancedIdService } from 'src/app/core/table-enhanced-id.service';
import { SharedUtils } from 'src/app/shared/shared-utils';
import { Sort } from "@angular/material/sort";
import { cloneDeep } from 'lodash';

import { FDN, Field, FormModes, IscFormModalData, VALIDATORS } from '@intersystems/isc-form';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl } from '@angular/forms';
import { ExternalObjectStorageConfiguration, ExternalObjectStorageConfigurationList, ExternalStorageObject, ExternalStorageObjectList } from 'src/app/deployments/icca-common/model/external-object-storage';
import { DeploymentsService } from 'src/app/deployments/deployments.service';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '@intersystems/notification';
import { SharedService } from 'src/app/shared/services/shared.service';
import { MatPaginator } from '@angular/material/paginator';
import { ConfirmationDialogComponent } from '@intersystems/confirmation-dialog';
import { ExternalObjectStorageService } from 'src/app/deployments/icca-common/services/external-object-storage.service';
import { FileHandlerService } from 'src/app/deployments/icca-common/services/file-handler.service';
import { FileListItem } from 'src/app/deployments/icca-common/model/file-list';



@Component({
  selector: 'app-sqlaas-external-object-storage-table',
  templateUrl: './external-object-storage-table.component.html',
  styleUrls: ['./external-object-storage-table.component.scss'],
})
export class SqlaasExternalObjectStorageTableComponent {
  
  
  @Input() selectedBucket: any;
  @Input() configs!: any;
  @Input() objects!: any;
  @Input() showField: 'empty' | 'bucketPolicy' | 'tableData' | 'error' = 'empty';
  

  @Output() deleteExternalObjectConfig = new EventEmitter<string>();
  @Output() addExternalObjectConfig = new EventEmitter<string>();

  @Output() transferObject = new EventEmitter<void>();
  @Output() loadExternalObjects = new EventEmitter<void>();
  @Output() rowsChanged = new EventEmitter<void>();
  
  constructor(
    public tableService: TableService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private externalObjectStorageService: ExternalObjectStorageService,
    private fileHandlerService: FileHandlerService,
    private route: ActivatedRoute,
    ) {
  }

  field: Field;
  error = '';
  selectedConfigRowId: string = null;
  objectLoaded:string = ''; 

  externalObjectStorageTableConfig: TableConfig = {
      "key": "externalObjectStorageTable",
      "header": {
        "title": "External storage locations",
      },
      cssTRClassFromRow: TableEnhancedIdService.setTableRowIdColumn('bucketArn'),
      noDataMessage: 'No external storage locations defined',
      highlightRowOnClick: true,
      rowIdentifierProperty: "bucketArn",
      onRowClick: (row: any) => {
        this.selectedConfigRowId = row.bucketArn;
        this.selectedBucket=row;
      },
      "columns": [
        {
          "title": "Bucket ARN",
          "key": "bucketArn",
          "id": "col-storage-configuration-bucket-arn",
          "sortable": true,
          "cellDisplay": {
            "model": "bucketArn"
          },
        },
        {
          "title": "Region",
          "key": "region",
          "id": "col-storage-configuration-region",
          cellDisplay: {
            "model": "region"
          }
        },
        {
          key: 'actions',
          id: 'actions',
          title: 'Actions',
          cellDisplay: {
            preset: 'actionsIcons',
            actionsIcons: {
              iconsOrder: ['delete'],
              delete: {
                id: 'delete',
                tooltip: { text: 'Delete external storage location' },
                callback: (event: any, row: any, col: any, rowIndex: number, paginator: MatPaginator) => {
                  this.launchDeleteObjectStorage(row);
                  return;
                },
              },
            },
          },
        }
      ],
    }
  "paginatorConfig": {
    "pageSize": "5"
  }
  formModel: any = {
    bucketARN: '',
    bucketRegion: '',
    nameSelected: '',
    deploymentId: '',
    rowsChecked: [],
    };
  bucketPolicy:string = '';
  buttonName = 'List files';
  fileTableData: any[]; //=[];
  rowsChecked: any[] = [];
  files: FileListItem[] = [];

  fileTableConfig: TableConfig = {
    key: 's3-files-table',
    header: {
      title: 'Select file(s) to transfer',
      titleTooltip: {
        text: 'The selected files will be transferred to the deployment.',
      },
    },
    noDataMessage: 'No files found in S3 bucket.',
    onRowClick: (row: any) => {
      row['select'] = !row['select'];
      try {
        this.fileTableConfig.columns
          .find(column => column.key === 'select')
          .cellDisplay.checkbox.onRowsChecked([row], row['select'], this.rowsChecked);
      } catch (e) {}
    },
    cssTRClassFromRow: TableEnhancedIdService.setTableRowIdColumn('name'),
    rowIdentifierProperty: 'name',
    columns: [
      {
        id: 'select',
        key: 'select',
        cellDisplay: {
          preset: 'checkbox',
          checkbox: {
            rowsChecked: [],
            rowCheckedStatusField: 'select',
            onRowsChecked: (rowsChecked, isCheck, allRowsChecked) => {
              this.onFileSelect(rowsChecked, isCheck, allRowsChecked);
            }
          },
        },
      },
      {
        sortable: false,
        title: 'Name',
        key: 'name',
        "id": "name",
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row.key;
          },
        },
      },
      {
        sortable: false,
        title: 'Size',
        key: 'size',
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return this.fileHandlerService.formatFileSize(row.size);
          },
        },
      },
      {
        sortable: false,
        title: 'Date Modified',
        key: 'lastModified',
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            var dateVal=row.lastModified;
            dateVal=dateVal.replace(/-/g, "/");
            dateVal=dateVal.replace(/T/g, " ");
            dateVal=dateVal.replace(/Z/g, "");
            //strip off decimal on the time if it exists.
            if (dateVal.indexOf('.')>0) {
              dateVal=dateVal.substr(0, dateVal.indexOf('.')); 
            }
            dateVal=dateVal.toLocaleString();
            return dateVal;   
            
            const test = new Date(row.lastModified.replace(/-/g, "/"));
            return test.toLocaleString(); //row.lastModifiedTime.toLocaleString();
          },
        },
      },
    ],
  };


  

  onFileSelect(rows,isCheck, allRowsChecked) {
    let index: number;
    //if (select) {
    if (isCheck) {
        rows.forEach(row => {
        index = this.rowsChecked.findIndex(checkedrow => checkedrow.key === row.key);
        if (index == -1) {
          this.rowsChecked.push(row);
        }
      });
    } else {
      rows.forEach(row => {
        index = this.rowsChecked.findIndex(checkedrow => checkedrow.key === row.key);
        if (index > -1) {
          this.rowsChecked.splice(index, 1);
        }
      });
    }
    var value:any = {rowsChecked: this.rowsChecked, selectedBucket: this.selectedBucket};
    this.rowsChanged.emit(value);
  }

 
  infoObject={
    title: {
      infoTitle:'Transfer files from external storage',
      htmlText: "To list the files in one of the associated S3 buckets so you can transfer \
      them to the deployment, select the bucket and click <b>List Files</b>.\
      <br><br>\
      To associate a new S3 bucket with the deployment, click <b>Add external storage</b> and \
      enter the bucket's ARN and region, which you can get by selecting the bucket on the \
      <a href='https://s3.console.aws.amazon.com/s3/buckets'  target='_blank'>AWS console's Buckets page</a>."
    },
    bucketPolicy:
      {
        infoTitle: 'Bucket policy',
        htmlText: "To grant your deployment access to the S3 bucket you are adding: \
        <ul>\
        <li>Copy the displayed bucket policy using the copy icon.</li>\
        <li>Go to the <a href='https://s3.console.aws.amazon.com/s3/buckets'  target='_blank'>AWS console's Buckets page</a> \
        and click the bucket's name to display its detail page.</li>\
        <li>Select the <b>Permissions</b> tab on the bucket's detail page and click <b>Edit</b> in the <b>Bucket Policy</b> section.</li>\
        <li>Paste the copied bucket policy from Cloud SQL into the edit area and click <b>Save changes</b>.</li>\
        </ul>\
        You can now press <b>List Files</b> to list the bucket's contents and transfer them to the deployment."
      },
      s3Transfer:
      {
        infoTitle: 'S3 Transfer',
        htmlText: "Enter the region and ARN of the bucket, as shown on the AWS Bucket Overview page. \
        The first time you list a bucket, you will need to grant your deployment access to it by associating the displayed \
        <a href='https://docs.aws.amazon.com/AmazonS3/latest/userguide/bucket-policies.html'  target='_blank'>bucket policy</a> with the bucket."
      }
  };

 
  
  launchObjectStorageInput() {

    var config:ExternalObjectStorageConfiguration = {bucketArn:'', region:''};
    
    this.externalObjectStorageService.openConfigInputModal(config, this.onAddConfigModalSubmit.bind(this));
  }

  async onAddConfigModalSubmit(formModel: any, form: any, dialogRef: MatDialogRef<any, any>) {
    if (form.valid) {
    
      this.addExternalObjectConfig.emit(formModel);
      dialogRef.close();
    } else {
      
    }
  }

   launchDeleteObjectStorage(row) {

    // Create a dialogConfig
    const dialogConfig = new MatDialogConfig();

    //this.confirmationDialogConfig.primary="You are about to delete '" + filename + "' from the server. Are you sure?";
    dialogConfig.data = {
      title: 'Remove link to external storage',
      primary: "You are about to remove the link to '" + row.bucketArn + "'. Are you sure?",
      buttons: {
        primary: {
          text: 'Remove external storage',
        },
        secondary: {
          text: 'Cancel',
        },
      },
    };

    dialogConfig.panelClass = 'fr-layout-wrapper-mat-dialog-panel';

    // Use the open() API to instantiate the modal
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    // Subscribe to the dialog's afterclosed() API to get the response
    dialogRef.afterClosed().subscribe(response => {
      if (response && response.button) {
        switch (response.button) {
          case 'primary':
            const config:any = {'bucketArn': row.bucketArn, 'region': row.region}
            this.deleteExternalObjectConfig.emit(config);
            break;
          case 'secondary':
          case 'tertiary':
            break;
        }
      }
    });
  }

  
  getBucketPolicy(policy) {
    return JSON.stringify(policy, null, 2);
  }

  listFiles() {
    this.objectLoaded=this.selectedBucket.bucketArn;
    this.fileTableConfig.header.title = `Select file(s) to transfer from ${this.objectLoaded}`
    this.rowsChecked=[];

    this.loadExternalObjects.emit(this.selectedBucket);
    
  }
 
 



}
