import { Component, OnDestroy, OnInit } from '@angular/core';
import { FDN, Field } from '@intersystems/isc-form';
import { FieldWrapper } from '@ngx-formly/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-isc-form-field-info-button-wrapper',
  templateUrl: './isc-form-field-info-button-wrapper.component.html',
  styleUrls: ['./isc-form-field-info-button-wrapper.component.scss'],
})
export class IscFormFieldInfoButtonWrapperComponent extends FieldWrapper implements OnInit, OnDestroy {
  field: Field;
  constructor () {
    super();
  }
  innerHTMLText='';

  ngOnInit(): void {
    this.formModel=this.field.model;
  }
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  formModel: any = {
    linkArray:[],
    htmlText:''
    };
  
 
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


}
