export const PASSWORD_LENGTH = '^.{12,}$';
export const CAPITAL = '[A-Z]';
export const LOWER = '[a-z]';
export const NUMBER = '[0-9]';
export const NUMBERS = '^[1-9][0-9]*$';
export const SPECIAL = '[!#$%&?@^*{}():;.,><`\\[\\]\\\\/\\_~|+=-]';
export const PATH = "[\\w /,!\\-_\\.\\*\\'\\(\\)]";
export const TAG = '[\\w ,_.\\:/\\=\\+\\-\\@]';
export const DOUBLE_SLASH = '//';
export const CIDR_BLOCK =
  '^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\\/(3[0-2]|[1-2][0-9]|[0-9]))$';
export const IP_OR_CIDR_BLOCK =
  '^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(/(3[0-2]|[1-2][0-9]|[0-9]))?$';
