/**
 * Portal
 * The ISC Portal is designed as an entrypoint for ISC to publish solutions to multiple cloud providers that are consumed by external users.  On this page, Swagger UI is already pre-authorised with your current JWT token and you can explore and try out APIs with your current user\'s credentials.  <code> !!! WARNING !!! Any command you issue from this page or using API calls will take an immediate effect on your deployments and data without any further confirmations. Please be very careful with POST and DELETE commands. </code>  If you want to use those APIs programmatically, you need to get JWT token first because it has a very limited expiration time.  There are multiple options to get JWT token from Amazon Cognito which our Cloud Portal use, the simplest one is to use curl and jq commands:  <code> TOKEN=$(curl -s \"https://cognito-idp.us-east-2.amazonaws.com/\" \\ -X POST \\ -d \'{\"AuthFlow\":\"USER_PASSWORD_AUTH\",\"ClientId\":\"76d6suqpq97v4fnvbg5oemieo9\",\"AuthParameters\":{\"USERNAME\":\"<USERNAME>\",\"PASSWORD\":\"<PASSWORD>\"}}\' \\ -H \"content-type: application/x-amz-json-1.1\" \\ -H \"X-Amz-Target: AWSCognitoIdentityProviderService.InitiateAuth\" | jq -r \'.AuthenticationResult.IdToken\'  ) </code>  After you get the token, you can use it to authenticate with InterSystems Cloud Portal, for example:  <code> curl \'https://portal.live.isccloud.io/v3/api/deployments\' \\ -X \'GET\' \\ -H \'accept: application/json\' \\ -H \"Authorization: Bearer $TOKEN\" </code> 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: portal-dev@intersystems.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { PostSqlGatewayConfigurationObject } from '../model/models';
import { SqlGatewayConfigurationObject } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class SQLGatewayService {

    protected basePath = 'https://portal.live.isccloud.io/v3/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Delete sql gateway configuration
     * @param tenantid 
     * @param deploymentid 
     * @param odbc 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSqlGatewayConfiguration(tenantid: string, deploymentid: string, odbc?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public deleteSqlGatewayConfiguration(tenantid: string, deploymentid: string, odbc?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public deleteSqlGatewayConfiguration(tenantid: string, deploymentid: string, odbc?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public deleteSqlGatewayConfiguration(tenantid: string, deploymentid: string, odbc?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (tenantid === null || tenantid === undefined) {
            throw new Error('Required parameter tenantid was null or undefined when calling deleteSqlGatewayConfiguration.');
        }
        if (deploymentid === null || deploymentid === undefined) {
            throw new Error('Required parameter deploymentid was null or undefined when calling deleteSqlGatewayConfiguration.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (odbc !== undefined && odbc !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>odbc, 'odbc');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearerAuth) required
        credential = this.configuration.lookupCredential('bearerAuth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/tenants/${encodeURIComponent(String(tenantid))}/deployments/${encodeURIComponent(String(deploymentid))}/odbc/${encodeURIComponent(String(odbc))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return list of sql gateway configurations for a specific deployment
     * @param tenantid 
     * @param deploymentid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSqlGatewayConfigurations(tenantid: string, deploymentid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<SqlGatewayConfigurationObject>>;
    public getSqlGatewayConfigurations(tenantid: string, deploymentid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<SqlGatewayConfigurationObject>>>;
    public getSqlGatewayConfigurations(tenantid: string, deploymentid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<SqlGatewayConfigurationObject>>>;
    public getSqlGatewayConfigurations(tenantid: string, deploymentid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (tenantid === null || tenantid === undefined) {
            throw new Error('Required parameter tenantid was null or undefined when calling getSqlGatewayConfigurations.');
        }
        if (deploymentid === null || deploymentid === undefined) {
            throw new Error('Required parameter deploymentid was null or undefined when calling getSqlGatewayConfigurations.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearerAuth) required
        credential = this.configuration.lookupCredential('bearerAuth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<SqlGatewayConfigurationObject>>(`${this.configuration.basePath}/tenants/${encodeURIComponent(String(tenantid))}/deployments/${encodeURIComponent(String(deploymentid))}/odbc`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create or edit sql gateway configuration
     * @param tenantid 
     * @param deploymentid 
     * @param odbc 
     * @param postSqlGatewayConfigurationObject 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postSqlGatewayConfiguration(tenantid: string, deploymentid: string, odbc?: string, postSqlGatewayConfigurationObject?: PostSqlGatewayConfigurationObject, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public postSqlGatewayConfiguration(tenantid: string, deploymentid: string, odbc?: string, postSqlGatewayConfigurationObject?: PostSqlGatewayConfigurationObject, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public postSqlGatewayConfiguration(tenantid: string, deploymentid: string, odbc?: string, postSqlGatewayConfigurationObject?: PostSqlGatewayConfigurationObject, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public postSqlGatewayConfiguration(tenantid: string, deploymentid: string, odbc?: string, postSqlGatewayConfigurationObject?: PostSqlGatewayConfigurationObject, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (tenantid === null || tenantid === undefined) {
            throw new Error('Required parameter tenantid was null or undefined when calling postSqlGatewayConfiguration.');
        }
        if (deploymentid === null || deploymentid === undefined) {
            throw new Error('Required parameter deploymentid was null or undefined when calling postSqlGatewayConfiguration.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (odbc !== undefined && odbc !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>odbc, 'odbc');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearerAuth) required
        credential = this.configuration.lookupCredential('bearerAuth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/tenants/${encodeURIComponent(String(tenantid))}/deployments/${encodeURIComponent(String(deploymentid))}/odbc/${encodeURIComponent(String(odbc))}`,
            postSqlGatewayConfigurationObject,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
